import React, { useCallback } from 'react';
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  SnackbarContentProps,
  SnackbarProps,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import styled from 'styled-components';
import { Close, CheckCircle, Error, SvgIconComponent } from '@material-ui/icons';

/* -------------------- DOM -------------------- */
type Props = {
  type: 'error' | 'success';
  message: string;
  icon: SvgIconComponent;
  open?: boolean;
  onClose?: () => void;
  contentProps?: SnackbarContentProps;
  wrapProps?: Omit<SnackbarProps, 'onClose' | 'open'>;
};

const Ui: React.FCX<Props> = ({ icon: Icon, ...rest }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    onClose={rest.onClose}
    open={!!rest.open}
    className={rest.className}
    {...rest.wrapProps}
  >
    <SnackbarContent
      className={rest.type}
      aria-describedby="client-snackbar"
      message={
        <span className="message">
          <Icon />
          {rest.message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={rest.onClose}>
          <Close />
        </IconButton>,
      ]}
      {...rest.contentProps}
    />
  </Snackbar>
);

/* ------------------- Style ------------------- */
const StyledUi = styled(Ui)`
  > div {
    background-color: ${(props) =>
      props.type === 'success' ? green[500] : props.theme.palette.error.dark};

    svg {
      font-size: 20px;
    }

    .message {
      display: flex;
      align-items: center;

      svg {
        margin-right: ${(props) => props.theme.spacing(1)}px;
      }
    }
  }
`;

/* ----------------- Container ----------------- */
type ContainerProps = Omit<Props, 'icon'>;

const Container: React.FCX<ContainerProps> = (props) => {
  const icon = icons[props.type];

  const onCloseProps = props.onClose;
  // autoHideDurationを渡されたとき onCloseがundefinedだとruntime エラーが発生するのでラムダで包んで必ず関数が呼べる状態にしてあげる
  const onClose = useCallback(() => {
    onCloseProps?.();
  }, [onCloseProps]);

  const innerProps: Props = {
    ...props,
    onClose,
    icon,
  };

  return <StyledUi {...innerProps} />;
};

const icons: { [P in Props['type']]: SvgIconComponent } = {
  error: Error,
  success: CheckCircle,
};

/*---------------------------------------------- */
export default Container;

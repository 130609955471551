import React, { ChangeEvent, ComponentProps } from 'react';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { CheckboxWithLabel } from './';
import { Obj } from '~/libs';

type ValueType = { label: string; checked: boolean };

type Props = ICellRendererParams & {
  inputProps?: ComponentProps<typeof CheckboxWithLabel>['inputProps'];
  onValueChanged?: (checkboxes: ValueType[]) => void;
};

type State = { checkboxes: ValueType[] };

class Container extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { checkboxes: verifyValues(props.value) };
  }

  render() {
    return this.state.checkboxes.map((checkbox, index) => {
      const inputProps: ComponentProps<typeof CheckboxWithLabel>['inputProps'] = {
        ...this.props.inputProps,
        checked: checkbox.checked,
        onChange: (e) => this.onChange(e, index),
      };

      return (
        <CheckboxWithLabel
          inputProps={inputProps}
          labelProps={{ 'aria-label': `${checkbox.label}-Label` }}
          labelText={checkbox.label}
          key={`${checkbox.label}-${index}`}
        />
      );
    });
  }

  onChange(e: ChangeEvent<HTMLInputElement>, index: number) {
    const checked = e.target.checked;

    const updateCheckboxes = [...this.state.checkboxes];
    updateCheckboxes[index].checked = checked;

    this.props.onValueChanged?.(updateCheckboxes);
    this.updateCheckboxState(updateCheckboxes);
  }

  updateCheckboxState(value: ValueType[]) {
    // NOTE: setTimeoutを使ったworkaround
    // https://github.com/facebook/react/issues/3005#issuecomment-72513965
    setTimeout(() => this.setState({ checkboxes: value }), 0);
  }

  refresh(params: ICellRendererParams) {
    this.updateCheckboxState(verifyValues(params.value));
    return true;
  }
}

const verifyValues = (value: unknown): ValueType[] => {
  if (!Array.isArray(value)) {
    return [];
  }

  return (value as unknown[]).reduce<ValueType[]>((result, v) => {
    if (Obj.isObject(v) && 'label' in v && 'checked' in v) {
      return result.concat({
        label: String(v.label),
        checked: !!v.checked,
      });
    }

    return result;
  }, []);
};

export type CellCheckBoxEditorParams = Pick<Props, 'inputProps' | 'onValueChanged'>;
export default Container;

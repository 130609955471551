import React from 'react';
import styled from 'styled-components';

const StyledUi = styled<React.FCX>((props) => (
  <footer {...props}>Created with Adways.Inc © STROBELIGHTS X 2018</footer>
))`
  margin: auto;
  padding: ${(props) => props.theme.spacing(2)}px;
  font-size: 8px;
`;

export default React.memo(() => <StyledUi />);

import { ChartData, ChartTooltipItem } from 'chart.js';
import { Formatter } from '~/libs';

export const withCurrency = (tooltipItem: ChartTooltipItem, chartData: ChartData) => {
  if (chartData.datasets == null || tooltipItem.datasetIndex == null || tooltipItem.index == null) {
    return '';
  }

  const dataSet = chartData.datasets[tooltipItem.datasetIndex];
  const data = dataSet.data ? dataSet.data[tooltipItem.index] : undefined;
  if (!dataSet.label || !(typeof data === 'number')) {
    return '';
  }

  return `${dataSet.label}: ${Formatter.formatCurrency(data, 'JPY')}`;
};

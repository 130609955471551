import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

type CalcData = {
  [key: string]: unknown;
};

export const summary = <T extends CalcData, K1 extends keyof T>(calcDatas: T[], sumColumns: K1[]) =>
  sumColumns.reduce((result, col) => {
    result[col] = null;

    calcDatas.forEach((data) => {
      const val = data[col];

      if (isString(val) && val !== '') {
        result[col] = Number(result[col]) + parseFloat(val);
      } else if (isNumber(val)) {
        result[col] = Number(result[col]) + val;
      }
    });

    return result;
  }, {} as Record<K1, number | null>);

const divide = (row: CalcData, numeratorKey: string, denominatorKey: string): number | null => {
  const numerator = row[numeratorKey];
  const denominator = row[denominatorKey];

  if (typeof numerator !== 'number' || typeof denominator !== 'number') {
    return null;
  }

  if (numerator === 0 || denominator === 0) {
    return 0;
  }

  return numerator / denominator;
};

const calcFunctions = {
  ctr: (arg: CalcData) => divide(arg, 'click', 'imp'),
  cvr: (arg: CalcData, cvCol = 'cv') => divide(arg, cvCol, 'click'),
  cpc: (arg: CalcData) => divide(arg, 'spend', 'click'),
  cpa: (arg: CalcData, cvCol = 'cv') => divide(arg, 'spend', cvCol),
  cpm: (arg: CalcData) => {
    const result = divide(arg, 'spend', 'imp');
    return result === null ? null : result * 1000;
  },
  ctvr: (arg: CalcData, cvCol = 'cv') => divide(arg, cvCol, 'imp'),
  spendRate: (arg: CalcData) => divide(arg, 'spend', 'budget'),
};

const calcCustomCvCol = <T extends { [key: string]: number | null }>(
  summaryDatas: T,
  col: string
) => {
  const match = /(cpa|cv|cvr|ctvr)([0-9]+)$/.exec(col);
  if (match && match[1] === 'cvr') {
    return calcFunctions.cvr(summaryDatas, `cv${match[2]}`);
  }

  if (match && match[1] === 'cpa') {
    return calcFunctions.cpa(summaryDatas, `cv${match[2]}`);
  }

  if (match && match[1] === 'ctvr') {
    return calcFunctions.ctvr(summaryDatas, `cv${match[2]}`);
  }

  return null;
};

const existsCalcFunctionKeys = (key: unknown): key is keyof typeof calcFunctions =>
  Object.keys(calcFunctions).includes(String(key));

export const calcTotalRow = <T extends CalcData, K1 extends keyof T, K2 extends keyof T>(
  calcDatas: T[],
  sumColumns: K1[],
  calcColumns: K2[]
) => {
  const summaryResult = summary(calcDatas, sumColumns);
  const calcResult = calcColumns.reduce((result, col) => {
    if (existsCalcFunctionKeys(col)) {
      result[col] = calcFunctions[col](summaryResult);
    } else {
      result[col] = calcCustomCvCol(summaryResult, String(col));
    }

    return result;
  }, {} as Record<K2, number | null>);

  return { ...summaryResult, ...calcResult };
};

export const sumWithoutAllNull = (values: Array<number | null>) =>
  values.every((v) => v == null)
    ? null
    : values.reduce<number>((result, v) => result + (v || 0), 0);

import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import floor from 'lodash/floor';
import Decimal from 'decimal.js';
import { Formatter } from '~/libs';

export const formatFloorCurrencyNumber = (params: ValueFormatterParams) => {
  if (params.value == null) {
    return '';
  }

  const floorValue = floor(params.value, 0);
  return formatCurrencyNumber({ ...params, ...{ value: floorValue } });
};

export const formatCurrencyNumber = (params: ValueFormatterParams) => {
  if (!params.data) {
    return '';
  }

  return Formatter.formatCurrency(params.value, params.data.currency) || '';
};

const defaultNumberDigit = '1.0-3';
export const formatNumber = (params: ValueFormatterParams, digit: string = defaultNumberDigit) =>
  Formatter.formatDecimal(params.value, digit) || '';

export const formatFloorNumber = (
  params: ValueFormatterParams,
  digit: string = defaultNumberDigit
) => {
  if (params.value == null) {
    return '';
  }

  const maxFractionDigits = digit.split('-')[1] || 0;
  const floorValue = floor(params.value, Number(maxFractionDigits));

  return formatNumber({ ...params, ...{ value: floorValue } }, digit);
};

const defaultPercentDigit = '1.2-2';
export const formatFloorPercent = (
  params: ValueFormatterParams,
  digit: string = defaultPercentDigit
) => {
  if (params.value == null) {
    return '';
  }

  const maxFractionDigits = digit.split('-')[1] || 0;
  // NOTE: 単純に 100掛けたあと切り捨てて100で割って戻すということはしない
  // 21.167 * 100
  //   => 2116.7000000000003 このように誤差が生じるのでDecimalを使って計算する
  const percentValue = new Decimal(params.value).mul(100);
  const floorValue = floor(percentValue.toNumber(), Number(maxFractionDigits));
  const floorRawValue = new Decimal(floorValue).div(100).toNumber();

  return formatPercent({ ...params, ...{ value: floorRawValue } }, digit);
};

export const formatPercent = (params: ValueFormatterParams, digit: string = defaultPercentDigit) =>
  Formatter.formatPercent(params.value, digit) || '';

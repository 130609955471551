import axios from 'axios';
import { Camelize } from '~/libs';

export type GetResponse = {
  adwaysDivisionId: number;
  id: number;
  name: string;
  unitName: string;
};

export interface GetRequestParams {
  recentMonth?: number;
}

export const getRequest = async (params: GetRequestParams) => {
  const response = await axios.get('/api/adways_units/bdg', {
    params: Camelize.underscoreKeysDeep(params),
  });

  return Camelize.camelizeKeysDeep(response.data) as GetResponse[];
};

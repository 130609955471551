import { useCallback } from 'react';
import { OptionsType, ValueType } from 'react-select';
import { useDispatch } from 'react-redux';
import { WithPayload } from '~/types';

export type CallBack<PullDownVal> = (
  pullDown: PullDownVal[],
  initialSetValues: PullDownVal[]
) => void;

export const useSetInitialPullDownValues = <
  InitialValues extends Array<PullDownVal['value']>,
  PullDownVal extends { value: unknown },
  Payload
>(
  initialValues: InitialValues,
  actionCreator: (v: PullDownVal[]) => WithPayload<Payload>,
  callback?: CallBack<PullDownVal>
) => {
  const dispatch = useDispatch();

  return useCallback(
    (pullDowns: PullDownVal[]) => {
      const verifiedPullDownValues = initialValues
        .map((id) => pullDowns.find((val) => val.value === id))
        .filter((v): v is NonNullable<typeof v> => v != null);

      dispatch(actionCreator(verifiedPullDownValues));
      callback?.(pullDowns, verifiedPullDownValues);
    },
    [dispatch, initialValues, actionCreator, callback]
  );
};

export const useSetSelectedValues = <T, P>(
  actionCreator: (v: OptionsType<T>) => WithPayload<P>,
  callback?: (v: OptionsType<T>) => void
) => {
  const dispatch = useDispatch();

  return useCallback(
    (pullDowns: ValueType<T, true>) => {
      if (pullDowns != null) {
        dispatch(actionCreator(pullDowns));
        callback?.(pullDowns);
      }
    },
    [dispatch, actionCreator, callback]
  );
};

export const useSetSelectedValue = <T, P>(
  actionCreator: (v: T) => WithPayload<P>,
  callback?: (v: T) => void
) => {
  const dispatch = useDispatch();

  return useCallback(
    (pullDowns: ValueType<T, false>) => {
      if (pullDowns != null) {
        dispatch(actionCreator(pullDowns));
        callback?.(pullDowns);
      }
    },
    [dispatch, actionCreator, callback]
  );
};

import React from 'react';
import { AppBar, Button, Hidden, IconButton, Toolbar } from '@material-ui/core';
import { Menu, PowerSettingsNew } from '@material-ui/icons';
import styled from 'styled-components';
import MainLogoSvg from '~/assets/logo.svg';
import XLogoSvg from '~/assets/logo-x.svg';
import FourTLogoSvg from '~/assets/logo-4t.svg';
import FourFLogoSvg from '~/assets/logo-4f.svg';
import FourGLogoSvg from '~/assets/logo-4g.svg';
import FourALogoSvg from '~/assets/logo-4a.svg';
import ExtensionLogoSvg from '~/assets/logo-ex.svg';
import { Constants } from '~/libs';

/* -------------------- DOM -------------------- */
type Props = {
  onMenuClick: () => void;
};

const Ui: React.FCX<Props> = (props) => (
  <AppBar className={props.className}>
    <Toolbar variant="dense" className="toolbar">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={props.onMenuClick}
        edge="start"
        className="mobile-menu-btn"
      >
        <Menu />
      </IconButton>
      <img className="main-logo" src={MainLogoSvg} alt="main" />
      <Hidden xsDown implementation="css">
        <div className="other-series">
          <Button className="x-btn">
            <img src={XLogoSvg} alt="x" />
          </Button>
          <Button
            className="t-btn"
            href={Constants.externalRoutes.fourT}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={FourTLogoSvg} alt="4t" />
          </Button>
          <Button
            className="f-btn"
            href={Constants.externalRoutes.fourF}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={FourFLogoSvg} alt="4f" />
          </Button>
          <Button
            className="g-btn"
            href={Constants.externalRoutes.fourG}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={FourGLogoSvg} alt="4g" />
          </Button>
          <Button
            className="a-btn"
            href={Constants.externalRoutes.fourA}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={FourALogoSvg} alt="4a" />
          </Button>
          <Button
            className="ext-btn"
            href={Constants.externalRoutes.extension}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src={ExtensionLogoSvg} alt="extension" />
          </Button>
        </div>
      </Hidden>
      <div className="spacer" />
      <IconButton href={Constants.externalRoutes.logout} className="logout-btn">
        <PowerSettingsNew />
      </IconButton>
    </Toolbar>
  </AppBar>
);

/* ------------------- Style ------------------- */
const StyledUi = styled(Ui)`
  z-index: ${(props) => props.theme.zIndex.drawer + 1};

  .toolbar {
    align-items: stretch;

    ${(props) => `${props.theme.breakpoints.up('sm')} {
      .mobile-menu-btn {
        display: none;
      }
    }`}

    .main-logo {
      width: 170px;
      margin-right: ${(props) => props.theme.spacing(3)}px;
    }

    .other-series {
      height: 100%;
      min-width: 475px;

      a,
      button {
        height: 100%;
        min-width: 32px;
        padding: 6px 12px;
        border-radius: 0px;
        img {
          width: 22px;
        }

        &.x-btn {
          background-color: red;
        }

        &.t-btn {
          &:hover {
            background-color: #00abff;
          }
        }

        &.f-btn {
          &:hover {
            background-color: #4267b2;
          }
        }

        &.g-btn {
          &:hover {
            background-color: #666666;
          }
        }

        &.y-btn {
          &:hover {
            background-color: #dd4b39;
          }
        }

        &.a-btn {
          &:hover {
            background-color: #999999;
          }
        }

        &.ext-btn {
          img {
            width: 125px;
          }

          &:hover {
            background-color: #cccccc;
          }
        }
      }
    }

    .spacer {
      flex: 1;
    }

    .logout-btn {
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;

/*---------------------------------------------- */
export default React.memo<Props>((props) => <StyledUi {...props} />);

import React, { ComponentProps } from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { DataTable } from '../fragments';

/* -------------------- DOM -------------------- */
type Props = ComponentProps<typeof DataTable> & {
  onCsvDownloadClicked?: () => void;
  onSubmitClicked?: () => void;
  submitText?: string;
  dataTableMinHeight?: number;
};

const Ui: React.FCX<Props> = ({
  onCsvDownloadClicked,
  onSubmitClicked,
  submitText = '登録',
  loadingOverlayComponentFramework = loading,
  rowHeight = 35,
  scrollbarWidth = 15,
  dataTableMinHeight,
  className,
  ...rest
}) => (
  <>
    <div className={`${className} datatable-container`}>
      <DataTable
        {...rest}
        loadingOverlayComponentFramework={loadingOverlayComponentFramework}
        rowHeight={rowHeight}
        scrollbarWidth={scrollbarWidth}
      />
    </div>
    <Grid container className={`${className} actions-container`}>
      <Grid item xs={12} sm={12}>
        {onCsvDownloadClicked && (
          <Button variant="contained" color="secondary" onClick={onCsvDownloadClicked} size="small">
            CSV Download
          </Button>
        )}
        {onSubmitClicked && (
          <Button variant="contained" color="primary" onClick={onSubmitClicked} size="small">
            {submitText}
          </Button>
        )}
      </Grid>
    </Grid>
  </>
);

const loading = () => <CircularProgress />;

/* ------------------- Style ------------------- */
const StyledUi: React.FCX<Props> = styled(Ui)`
  &.datatable-container {
    display: flex;
    flex: 1;
    > div.ag-theme-balham {
      width: 100%;
      ${(props) => props.dataTableMinHeight && `min-height: ${props.dataTableMinHeight}px;`}
    }
  }

  &.actions-container {
    margin-top: ${(props) => props.theme.spacing(1)}px;
    text-align: right;

    button {
      margin-left: ${(props) => props.theme.spacing(2)}px;
    }
  }
`;

/*---------------------------------------------- */
export default StyledUi;

import React, { ComponentProps } from 'react';
import {
  ControlPointDuplicate,
  CheckCircle,
  MoneyOff,
  SettingsApplications,
  NotificationImportant,
  CloudDownload,
  List as ListIcon,
  FeaturedPlayList,
  Share,
  AccountTree,
  InsertChartOutlined,
  Home,
  ArrowDropDown,
  ArrowRight,
} from '@material-ui/icons';
import { TreeView } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { SideNavItem } from '../fragments';
import routes from '~/routes';
import { Constants } from '~/libs';

/* -------------------- DOM -------------------- */
type Props = Partial<
  Pick<ComponentProps<typeof SideNavItem>, 'bgColor' | 'textColor'> & {
    onItemClick: () => void;
    isAdmin: boolean;
  }
> & {
  router: ReturnType<typeof useHistory>;
};

const Ui: React.FCX<Props> = ({ onItemClick, isAdmin, router, className, ...rest }) => (
  <TreeView
    defaultCollapseIcon={<ArrowDropDown />}
    defaultExpandIcon={<ArrowRight />}
    defaultExpanded={['2', '5']}
    defaultEndIcon={<div style={{ width: 24 }} />}
    className={className}
  >
    <SideNavItem
      nodeId="1"
      labelText={'ダッシュボード'}
      labelIcon={Home}
      internalLink={routes.overview !== router.location.pathname ? routes.overview : undefined}
      className={routes.overview === router.location.pathname ? 'active' : ''}
      onClick={onItemClick}
      {...rest}
    />
    <SideNavItem
      nodeId="2"
      labelText="数値レポート"
      labelIcon={InsertChartOutlined}
      className="nested"
      {...rest}
    >
      <SideNavItem
        nodeId="2-1"
        labelText="案件別"
        labelIcon={AccountTree}
        internalLink={
          routes.summary.promotion !== router.location.pathname
            ? routes.summary.promotion
            : undefined
        }
        className={routes.summary.promotion === router.location.pathname ? 'active' : ''}
        {...rest}
      />
      <SideNavItem
        nodeId="2-2"
        labelText="媒体別"
        labelIcon={Share}
        internalLink={
          routes.summary.menu !== router.location.pathname ? routes.summary.menu : undefined
        }
        className={routes.summary.menu === router.location.pathname ? 'active' : ''}
        {...rest}
      />
      <SideNavItem
        nodeId="2-3"
        labelText="日別"
        labelIcon={FeaturedPlayList}
        internalLink={
          routes.summary.daily !== router.location.pathname ? routes.summary.daily : undefined
        }
        className={routes.summary.daily === router.location.pathname ? 'active' : ''}
        {...rest}
      />
      <SideNavItem
        nodeId="2-4"
        labelText="月別"
        labelIcon={ListIcon}
        internalLink={
          routes.summary.monthly !== router.location.pathname ? routes.summary.monthly : undefined
        }
        className={routes.summary.monthly === router.location.pathname ? 'active' : ''}
        {...rest}
      />
    </SideNavItem>
    <SideNavItem
      nodeId="3"
      labelText="案件x媒体x日別"
      labelIcon={CloudDownload}
      internalLink={
        routes.reportDownload !== router.location.pathname ? routes.reportDownload : undefined
      }
      className={routes.reportDownload === router.location.pathname ? 'active' : ''}
      {...rest}
    />
    <SideNavItem
      nodeId="4"
      labelText="アラート一覧"
      labelIcon={NotificationImportant}
      internalLink={routes.alert !== router.location.pathname ? routes.alert : undefined}
      className={routes.alert === router.location.pathname ? 'active' : ''}
      {...rest}
    />
    <SideNavItem
      nodeId="5"
      labelText="設定"
      labelIcon={SettingsApplications}
      className="nested"
      {...rest}
    >
      <SideNavItem
        nodeId="5-1"
        labelText="CPAアラート"
        labelIcon={NotificationImportant}
        internalLink={
          routes.configuration.targetCpa !== router.location.pathname
            ? routes.configuration.targetCpa
            : undefined
        }
        className={routes.configuration.targetCpa === router.location.pathname ? 'active' : ''}
        {...rest}
      />
      <SideNavItem
        nodeId="5-2"
        labelText="消化アラート"
        labelIcon={MoneyOff}
        internalLink={
          routes.configuration.budget !== router.location.pathname
            ? routes.configuration.budget
            : undefined
        }
        className={routes.configuration.budget === router.location.pathname ? 'active' : ''}
        {...rest}
      />
      <SideNavItem
        nodeId="5-3"
        labelText="コンバージョン"
        labelIcon={CheckCircle}
        internalLink={
          routes.configuration.multiCv !== router.location.pathname
            ? routes.configuration.multiCv
            : undefined
        }
        className={routes.configuration.multiCv === router.location.pathname ? 'active' : ''}
        {...rest}
      />
    </SideNavItem>
    <SideNavItem
      nodeId="6"
      labelText="その他"
      labelIcon={ControlPointDuplicate}
      className="nested"
      {...rest}
    >
      <SideNavItem
        nodeId="6-1"
        labelText="Tableau"
        externalLink={{
          href: Constants.externalRoutes.tableau,
          target: '_blank',
          rel: 'noreferrer noopener',
        }}
        {...rest}
      />
      <SideNavItem
        nodeId="6-2"
        labelText="Skuid"
        externalLink={{
          href: Constants.externalRoutes.skuid,
          target: '_blank',
          rel: 'noreferrer noopener',
        }}
        {...rest}
      />
      <SideNavItem
        nodeId="6-3"
        labelText="対応媒体一覧"
        internalLink={routes.menus !== router.location.pathname ? routes.menus : undefined}
        className={routes.menus === router.location.pathname ? 'active' : ''}
        {...rest}
      />
      <SideNavItem
        nodeId="6-4"
        labelText="説明資料"
        externalLink={{
          href: Constants.externalRoutes.help,
          target: '_blank',
          rel: 'noreferrer noopener',
        }}
        {...rest}
      />
    </SideNavItem>
    {isAdmin ? (
      <SideNavItem nodeId="7" labelText="管理画面" className="nested" {...rest}>
        <SideNavItem
          nodeId="7-1"
          labelText="TTS"
          internalLink={
            routes.admin.configuration.currencyRate !== router.location.pathname
              ? routes.admin.configuration.currencyRate
              : undefined
          }
          className={
            routes.admin.configuration.currencyRate === router.location.pathname ? 'active' : ''
          }
          {...rest}
        />
        <SideNavItem
          nodeId="7-2"
          labelText="メニュー"
          internalLink={
            routes.admin.configuration.menu !== router.location.pathname
              ? routes.admin.configuration.menu
              : undefined
          }
          className={routes.admin.configuration.menu === router.location.pathname ? 'active' : ''}
          {...rest}
        />
        <SideNavItem
          nodeId="7-3"
          labelText="Slackチャンネル連携"
          internalLink={
            routes.admin.configuration.promotionChannel !== router.location.pathname
              ? routes.admin.configuration.promotionChannel
              : undefined
          }
          className={`${
            routes.admin.configuration.promotionChannel === router.location.pathname ? 'active' : ''
          } dense`}
          {...rest}
        />
        <SideNavItem
          nodeId="7-4"
          labelText="レポート取得"
          internalLink={
            routes.admin.reportReacquisition !== router.location.pathname
              ? routes.admin.reportReacquisition
              : undefined
          }
          className={routes.admin.reportReacquisition === router.location.pathname ? 'active' : ''}
          {...rest}
        />
      </SideNavItem>
    ) : (
      <></>
    )}
  </TreeView>
);

/* ------------------- Style ------------------- */
const StyledUi: React.FCX<Props> = styled(Ui)`
  > li {
    &.nested > ul {
      margin-left: 16px;
    }

    &:nth-of-type(n + 2) {
      margin-top: ${(props) => props.theme.spacing(1)}px;
    }

    &.fallback-angular {
      svg {
        color: red;
      }

      p {
        font-weight: bold;
      }
    }

    .dense {
      a p {
        font-size: 0.8rem;
      }
    }
  }
`;

/* ----------------- Container ----------------- */
type ContainerProps = Omit<Props, 'router'>;

const Container: React.FCX<ContainerProps> = (props) => {
  const router = useHistory();

  return <StyledUi {...props} router={router} />;
};

/*---------------------------------------------- */
export default Container;

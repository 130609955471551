import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import Pulldown, { PulldownProps } from './Pulldown';
import { OptionTypeDefault } from '~/types';

export type Props<IsMulti extends boolean = false> = PulldownProps<OptionTypeDefault, IsMulti> & {
  onReady?: (options: OptionTypeDefault[]) => void;
};

const Container = React.memo(({ onReady, ...props }: Props) => {
  const recent6Months = useMemo(getRecent6Months, []);

  useEffect(() => {
    onReady && onReady(recent6Months);
  }, [onReady, recent6Months]);

  return <Pulldown {...props} options={recent6Months} />;
}) as {
  <IsMulti extends boolean = false>(props: Props<IsMulti>): JSX.Element;
  readonly type: <IsMulti extends boolean = false>(props: Props<IsMulti>) => JSX.Element; // テストコードで参照しているのでなるべく React.memo が返す戻り値と同じような型でキャストする
  readonly $$typeof: symbol;
  displayName?: string;
};

const getRecent6Months = () =>
  Array.from({ length: 6 }, (_, i) => {
    const m = dayjs().subtract(i, 'month').startOf('month');

    return {
      value: m.format('YYYY-MM-DD'),
      label: m.format('YYYY年MM月'),
    };
  });

export default Container;

import React, { ComponentProps } from 'react';
import { Bar, ChartComponentProps, defaults } from 'react-chartjs-2';
import styled from 'styled-components';
import { ToggleLabel } from '../fragments';
import { main } from '~/theme';

// NOTE: 本来は上流のコンポーネントから流れてくるテーマのpropsの値を使って配色を決定した方がいいのだが
// chart.jsに渡すべきフォントの色のpropsが多く面倒なので横着して `main` のテーマの値をハードコードしてます
defaults.global.defaultFontColor = main.palette.text.primary;

/* -------------------- DOM -------------------- */
type ToggleLabelProps = ComponentProps<typeof ToggleLabel>;

type Props = ChartComponentProps & {
  toggleState: ToggleLabelProps['toggleState'];
  onToggleLabelClicked: ToggleLabelProps['onClick'];
};

const Ui: React.FCX<Props> = ({
  toggleState,
  onToggleLabelClicked,
  height,
  className,
  ...rest
}) => (
  <div className={className}>
    <ToggleLabel
      className="toggle-label"
      toggleState={toggleState}
      text="グラフ"
      onClick={onToggleLabelClicked}
    />
    <div className={!toggleState ? 'hide' : ''}>
      <Bar {...rest} height={height || 200} />
    </div>
  </div>
);

/* ------------------- Style ------------------- */
const StyledUi: React.FCX<Props> = styled(Ui)`
  .hide {
    display: none;
  }
`;

/*---------------------------------------------- */
export default StyledUi;

import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';

const routes = {
  overview: '/',
  summary: {
    promotion: '/summaries/promotion',
    menu: '/summaries/menu',
    daily: '/summaries/daily',
    monthly: '/summaries/monthly',
  },
  alert: '/notified_list',
  reportDownload: '/report/download',
  configuration: {
    targetCpa: '/configurations/target-cpa',
    budget: '/configurations/budget',
    multiCv: '/configurations/multi-cv',
  },
  menus: '/menus/supported_list',
  admin: {
    configuration: {
      menu: '/admin/configuration/menu',
      promotionChannel: '/admin/configuration/promotion_channel',
      currencyRate: '/admin/configuration/currency_rate',
    },
    reportReacquisition: '/admin/reacquisition/report',
  },
};

type LazyComponentCreatorType = (Component: React.ComponentType) => React.FCX<RouteComponentProps>;

const LazyComponentCreator: LazyComponentCreatorType = (Component) => (props) =>
  (
    <React.Suspense fallback={<LinearProgress />}>
      <Component {...props} />
    </React.Suspense>
  );

export const Routes: React.FCX = (props) =>
  // prettier-ignore
  <Switch>
    <Route exact path={routes.overview} component={LazyComponentCreator( React.lazy(() => import('./components/Overview')) )} />
    <Route exact path={routes.summary.promotion} component={LazyComponentCreator( React.lazy(() => import('./components/Summaries/Promotion')) )} />
    <Route exact path={routes.summary.menu} component={LazyComponentCreator( React.lazy(() => import('./components/Summaries/Menu')) )} />
    <Route exact path={routes.summary.daily} component={LazyComponentCreator( React.lazy(() => import('./components/Summaries/Daily')) )} />
    <Route exact path={routes.summary.monthly} component={LazyComponentCreator( React.lazy(() => import('./components/Summaries/Monthly')) )} />
    <Route exact path={routes.alert} component={LazyComponentCreator( React.lazy(() => import('./components/Alert')) )} />
    <Route exact path={routes.reportDownload} component={LazyComponentCreator( React.lazy(() => import('./components/ReportDownload')) )} />
    <Route exact path={routes.configuration.targetCpa} component={LazyComponentCreator( React.lazy(() => import('./components/Configurations/TargetCpa')) )} />
    <Route exact path={routes.configuration.budget} component={LazyComponentCreator( React.lazy(() => import('./components/Configurations/Budget')) )} />
    <Route exact path={routes.configuration.multiCv} component={LazyComponentCreator( React.lazy(() => import('./components/Configurations/MultiCv')) )} />
    <Route exact path={routes.menus} component={LazyComponentCreator( React.lazy(() => import('./components/Menu')) )} />
    {/* admin */}
    <Route exact path={routes.admin.configuration.menu} component={LazyComponentCreator( React.lazy(() => import('./components/Admin/Configurations/Menu')) )} />
    <Route exact path={routes.admin.configuration.promotionChannel} component={LazyComponentCreator( React.lazy(() => import('./components/Admin/Configurations/PromotionChannel')) )} />
    <Route exact path={routes.admin.configuration.currencyRate} component={LazyComponentCreator( React.lazy(() => import('./components/Admin/Configurations/CurrencyRate')) )} />
    <Route exact path={routes.admin.reportReacquisition} component={LazyComponentCreator( React.lazy(() => import('./components/Admin/Configurations/ReportReacquisition')) )} />
    <Route component={LazyComponentCreator( React.lazy(() => import('./components/Errors/404')) )} />
  </Switch>;

export default routes;

import axios from 'axios';
import pick from 'lodash/pick';
import { Camelize } from '~/libs';

type Data = {
  id: number;
  name: string;
  promotionType: number;
  marginType: number;
  marginRate: string;
  cabinetName: string | null;
  commodityId: number;
  commodity: string;
  cooperateStartDate: string | null;
  cooperateEndDate: string | null;
  isSupported: boolean;
  isSupportedMultiCv: boolean;
  defaultCvDescription: string;
  note: string;
  createdAt: string;
  updatedAt: string;
};

export type GetResponse = Data[];

export const getRequest = async () => {
  const response = await axios.get('/api/menus');

  return Camelize.camelizeKeysDeep(response.data) as GetResponse;
};

export const convertPostRequestDataFromGetResponse = (data: GetResponse): PostRequestParams => {
  const settings = data.map((row) =>
    pick(row, [
      'id',
      'commodity',
      'cooperateStartDate',
      'cooperateEndDate',
      'isSupported',
      'isSupportedMultiCv',
      'defaultCvDescription',
      'note',
    ])
  );

  return { settings };
};

export type PostResponse = true;

type PickedData = Pick<
  Data,
  | 'id'
  | 'commodity'
  | 'cooperateStartDate'
  | 'cooperateEndDate'
  | 'isSupported'
  | 'isSupportedMultiCv'
  | 'defaultCvDescription'
  | 'note'
>;
export type PostRequestParams = {
  settings: PickedData[];
};

export const postRequest = async (params: PostRequestParams) => {
  const response = await axios.post('/api/menus', Camelize.underscoreKeysDeep(params));

  return Camelize.camelizeKeysDeep(response.data) as PostResponse;
};

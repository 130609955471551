import React, { createRef, RefObject } from 'react';
import ReactSelect, { ValueType } from 'react-select';
import { ICellEditorParams, ColDef } from '@ag-grid-enterprise/all-modules';
import { ICellEditorReactComp } from '@ag-grid-community/react';
import styled from 'styled-components';
import { Styles } from 'react-select';
import Pulldown, { PulldownProps } from './Pulldown';
import { ReactSelect as ReactSelectUtils } from '~/libs';

/* ------------------- Style ------------------- */
type StyledUiProps<T> = PulldownProps<T> & {
  width: number;
  minWidth: number;
};

type StyledUiType = <T>(props: StyledUiProps<T>) => JSX.Element;

const StyledUi: StyledUiType = styled<StyledUiType>(Pulldown)`
  > div {
    width: ${(props) => props.width}px;
    min-width: ${(props) => props.minWidth}px;
  }
`;

/* ----------------- Container ----------------- */
type ContainerProps<
  InputVal,
  Options extends { value: InputVal } = { value: InputVal }
> = ICellEditorParams & {
  rowHeight?: number;
  minWidth: number;
  isFocusNextCellAfterEdit?: boolean;
  optionSelectProps: Omit<PulldownProps<Options>, 'isMulti'>; // 一旦 IsMulti => false 固定で要件を満たせるので true 対応はしない
  onValueChanged?: (value: InputVal) => void;
};

class Container<InputVal, Options extends { value: InputVal } = { value: InputVal }>
  extends React.PureComponent<ContainerProps<InputVal, Options>, { inputValue: InputVal }>
  implements ICellEditorReactComp
{
  reactSelectRef: RefObject<ReactSelect<Options>>;
  lastKeyDownCode = '';
  isChangingValue = false;
  rowHeight: number;
  isFocusNextCellAfterEdit: boolean;

  constructor(props: ContainerProps<InputVal, Options>) {
    super(props);

    this.state = { inputValue: props.value };
    this.rowHeight = props.rowHeight || 33;
    this.isFocusNextCellAfterEdit =
      props.isFocusNextCellAfterEdit !== undefined ? props.isFocusNextCellAfterEdit : true;
    this.reactSelectRef = createRef();
  }

  componentDidMount(): void {
    // react-selectの描画が完了されるまでラグがあるので多少待機してからfocusを呼ぶ
    setTimeout(() => {
      this.reactSelectRef.current?.select.focus();
    }, 10);
  }

  render() {
    const control: Styles<Options, false>['control'] = (base) => ({
      ...base,
      height: this.rowHeight,
      minHeight: this.rowHeight,
    });

    const dropdownIndicator: Styles<Options, false>['dropdownIndicator'] = (base) => ({
      ...base,
      padding: 4,
    });

    const value = ReactSelectUtils.isOptionsType<Options>(this.props.optionSelectProps.options)
      ? this.props.optionSelectProps.options.find((v) => v.value === this.state.inputValue)
      : undefined;

    return (
      <StyledUi
        width={this.props.column.getActualWidth()}
        minWidth={this.props.minWidth}
        styles={{ control, dropdownIndicator }}
        value={value}
        onBlur={this.onBlur.bind(this)}
        openMenuOnFocus
        ref={this.reactSelectRef}
        onChange={this.onChange.bind(this)}
        onKeyDown={this.onKeyDown.bind(this)}
        {...this.props.optionSelectProps}
      />
    );
  }

  onKeyDown(e: React.KeyboardEvent<HTMLElement>) {
    this.lastKeyDownCode = e.key;
  }

  onChange(selectedValue: ValueType<Options, false>) {
    if (Array.isArray(selectedValue) || selectedValue == null) {
      return;
    }

    this.isChangingValue = true;
    this.setState({ inputValue: selectedValue.value }, () => {
      this.isChangingValue = false;

      // UXを向上させるため tab/enter でプルダウンを確定させた場合はそのまま入力を完了させた状態にする
      if (this.lastKeyDownCode === 'Tab' || this.lastKeyDownCode === 'Enter') {
        this.focusOut();
      }
    });
  }

  onBlur() {
    if (!this.isChangingValue) {
      this.focusOut();
    }
  }

  getValue() {
    return this.state.inputValue;
  }

  focusOut() {
    this.props.onValueChanged?.(this.getValue());
    this.props.stopEditing(!this.isFocusNextCellAfterEdit);
  }

  isPopup(): boolean {
    return true;
  }
}

const suppressKeyboardEvent: ColDef['suppressKeyboardEvent'] = (params) => {
  const keyCode = params.event.key;
  return params.editing && (keyCode === 'Tab' || keyCode === 'Enter');
};

/*---------------------------------------------- */
export { suppressKeyboardEvent as PulldownCellEditorSuppressKeyboardEvent };

export type PulldownCellEditorProps<
  InputVal,
  Options extends { value: InputVal } = { value: InputVal }
> = ContainerProps<InputVal, Options>;

export type PullDownCellEditorParams<
  InputVal,
  Options extends { value: InputVal } = { value: InputVal }
> = Pick<
  ContainerProps<InputVal, Options>,
  'rowHeight' | 'minWidth' | 'isFocusNextCellAfterEdit' | 'optionSelectProps' | 'onValueChanged'
>;

export default Container;

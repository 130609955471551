import 'stop-runaway-react-effects/hijack';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import App, { history } from './App';
import * as serviceWorker from './serviceWorker';
import { ReduxStore, UserSession } from './state';

ReactDOM.render(
  <Provider store={ReduxStore.default}>
    <Router history={history}>
      <CookiesProvider>
        <UserSession.SessionStateProvider>
          <App />
        </UserSession.SessionStateProvider>
      </CookiesProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (process.env.NODE_ENV === 'development' && (module as any).hot) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (module as any).hot.accept('./state/redux_store', () => {
    // HMR時にreduxストアの中身を維持する
    ReduxStore.default.replaceReducer(require('./state/redux_store').rootReducer);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { ComponentProps, useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import styled from 'styled-components';
import { CssBaseline, Drawer, Hidden } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import routes, { Routes } from './routes';
import * as Themes from './theme';
import { Header, SideNav, MobileSideNav, Footer } from './components/_shared/groups';
import { Constants, ServiceAnalytics, useToggleState } from './libs';
import { UserSession, ReduxStore } from './state';

/* -------------------- DOM -------------------- */
type MobileSideNavProps = ComponentProps<typeof MobileSideNav>;

type Props = ComponentProps<typeof Routes> & {
  toggleMobileDrawer: () => void;
  isMobileDrawerOpen: boolean;
  isAdmin: boolean;
  mobileSideNavItemBgColorStyle?: MobileSideNavProps['bgColor'];
  mobileSideNavItemTextColorStyle?: MobileSideNavProps['textColor'];
};

const Ui: React.FCX<Props> = (props) => (
  <div className={props.className}>
    <CssBaseline />
    <Themes.ThemeProvider theme={Themes.dark}>
      <Header onMenuClick={props.toggleMobileDrawer} />
    </Themes.ThemeProvider>

    <Hidden smUp implementation="css">
      <Drawer
        variant="temporary"
        open={props.isMobileDrawerOpen}
        onClose={props.toggleMobileDrawer}
        // Better open performance on mobile.
        ModalProps={{ keepMounted: true }}
        PaperProps={{ className: 'paper' }}
        className={`${props.className} mobile-drawer`}
      >
        <MobileSideNav
          onItemClick={props.toggleMobileDrawer}
          bgColor={props.mobileSideNavItemBgColorStyle}
          textColor={props.mobileSideNavItemTextColorStyle}
        />
      </Drawer>
    </Hidden>

    <Themes.ThemeProvider theme={Themes.dark}>
      <Hidden xsDown implementation="css">
        <Drawer variant="permanent" className="desktop-drawer" PaperProps={{ className: 'paper' }}>
          <NavBarSpacer />
          <SideNav isAdmin={props.isAdmin} />
          <Footer />
        </Drawer>
      </Hidden>
    </Themes.ThemeProvider>

    <div className="container">
      <main>
        <NavBarSpacer />
        <CachedRoutes />
      </main>
      <Hidden smUp implementation="css">
        <Footer />
      </Hidden>
    </div>
  </div>
);

const NavBarSpacer = () => <div className="nav-bar-spacing" />;
const CachedRoutes = React.memo<ComponentProps<typeof Routes>>((props) => <Routes {...props} />);

/* ------------------- Style ------------------- */
const drawerWidth = 195;

const StyledUi: React.FCX<Props> = styled<React.FCX<Props>>((props) => (
  <Ui
    {...props}
    mobileSideNavItemBgColorStyle={(props) => props.theme.palette.primary.main}
    mobileSideNavItemTextColorStyle={(props) => props.theme.palette.background.default}
  />
))`
  display: flex;

  &.mobile-drawer {
    .paper {
      padding: ${(props) => props.theme.spacing(1.5)}px;
      min-width: 210px;
    }
  }

  .desktop-drawer {
    width: ${drawerWidth}px;

    .paper {
      padding: ${(props) => props.theme.spacing(2)}px;
      // NOTE: この要素は position: fixed がついているので親要素のwidthに左右されないので同じスタイルを当てる
      // width: inherit でもいいかも
      width: ${drawerWidth}px;

      footer {
        display: flex;
        align-items: flex-end;
        flex: 1;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;

    width: 100%;
    ${(props) => props.theme.breakpoints.up('sm')} {
      width: calc(100% - ${drawerWidth}px);
    }

    min-height: 100vh;

    main {
      padding: ${(props) => props.theme.spacing(2)}px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    footer {
      text-align: center;
    }
  }

  .nav-bar-spacing {
    min-height: 44px;
  }
`;

const MuiThemeUi: React.FCX<Props> = (props) => (
  <StylesProvider injectFirst>
    <Themes.ThemeProvider theme={Themes.main}>
      <StyledUi {...props} />
    </Themes.ThemeProvider>
  </StylesProvider>
);

/* ----------------- Container ----------------- */
const Container: React.FCX<RouteComponentProps> = (props) => {
  const { userInfo, auth } = useContext(UserSession.userSessionStore);

  if (auth === 'unauthorized') {
    window.location.href = `${Constants.externalRoutes.login}?redirect_url=${encodeURIComponent(
      window.location.href
    )}`;
    return null;
  }

  if (auth === 'pending' || !userInfo) {
    return null;
  }

  return <AuthorizedContainer userInfo={userInfo} {...props} />;
};

const AuthorizedContainer: React.FCX<
  RouteComponentProps & {
    userInfo: NonNullable<UserSession.State['userInfo']>;
  }
> = ({ userInfo, history }) => {
  const [isMobileDrawerOpen, toggleMobileDrawer] = useToggleState(false);

  React.useEffect(() => {
    // イベントリスナを登録する初回は発火しないので直接呼び出す
    trackPageViewEventListener(history.location, userInfo);

    return history.listen((location) => {
      trackPageViewEventListener(location, userInfo);
    });
  }, [history, userInfo]);

  const innerProps: Props = {
    isMobileDrawerOpen,
    toggleMobileDrawer,
    isAdmin: userInfo.isAdmin,
  };

  return <MuiThemeUi {...innerProps} />;
};

/* -------- ルーティング変化時のイベントリスナ ------- */
const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

history.listen((location) => {
  ReduxStore.default.dispatch({ type: ReduxStore.changeRoute });
});

const trackPageViewEventListener = (
  location: RouteComponentProps['history']['location'],
  userInfo: NonNullable<UserSession.State['userInfo']>
) => {
  // NOTE: ダッシュボード(`/`) は `/overview` としてトラッキングする
  const pagePath = location.pathname === routes.overview ? '/overview' : location.pathname;

  ServiceAnalytics.trackPageView(
    pagePath + location.search,
    userInfo.nicknameHash,
    userInfo.unitName
  );
};

/*---------------------------------------------- */
export default withRouter(Container);
export { history };

import React from 'react';
import styled from 'styled-components';

/* -------------------- DOM -------------------- */
type Props = {
  messages: string[];
};

const Ui: React.FCX<Props> = (props) => (
  <ul className={props.className}>
    {props.messages.map((message) => (
      <li key={message}>{message}</li>
    ))}
  </ul>
);

/* ------------------- Style ------------------- */
const StyledUi: React.FCX<Props> = styled(Ui)`
  padding: 0;
  margin: 8px 14px 0px 0px;
  list-style: none;
  li {
    color: ${(props) => props.theme.palette.error.main};
  }
`;

/*---------------------------------------------- */
export default StyledUi;

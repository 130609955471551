import React, { useCallback, useEffect } from 'react';
import Pulldown, { PulldownProps } from './Pulldown';
import * as PromotionsApi from '~/libs/api/promotions';
import { useApiRequest } from '~/libs';
import { OptionTypeDefault } from '~/types';

export type Props<IsMulti extends boolean = false> = PulldownProps<OptionTypeDefault, IsMulti> & {
  recentMonth?: number;
  withoutSpend?: boolean;
  ym?: string;
  onReady?: (options: OptionTypeDefault[]) => void;
};

const Container = React.memo(({ onReady, ...props }: Props) => {
  const state = useApiRequest(
    [],
    useCallback(
      () => () =>
        PromotionsApi.getRequest({
          recentMonth: props.recentMonth,
          withoutSpend: props.withoutSpend,
          ym: props.ym,
        }),
      [props.recentMonth, props.withoutSpend, props.ym]
    )
  );

  const options = React.useMemo(
    () => state.data.map((v) => ({ label: v.name, value: String(v.id) })),
    [state.data]
  );

  useEffect(() => {
    if (state.progress === 'success' && onReady) {
      onReady(options);
    }
  }, [options, onReady, state.progress]);

  return <Pulldown {...props} isLoading={state.progress === 'loading'} options={options} />;
}) as {
  <IsMulti extends boolean = false>(props: Props<IsMulti>): JSX.Element;
  readonly type: <IsMulti extends boolean = false>(props: Props<IsMulti>) => JSX.Element; // テストコードで参照しているのでなるべく React.memo が返す戻り値と同じような型でキャストする
  readonly $$typeof: symbol;
  displayName?: string;
};

export default Container;

import * as React from 'react';
import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

/* -------------------- DOM -------------------- */
type Row = {
  links?: Array<LinkProps & { key: string }>;
  text: string;
  key: string;
  onDeleteClicked?: () => void;
  deleteText?: string;
};

type Props = {
  rows?: Row[];
  title: string;
  maxHeight?: number;
  type: 'warn' | 'error';
  onDeleteClicked?: () => void;
  deleteText?: string;
};

const Ui: React.FCX<Props> = (props) => (
  <blockquote className={props.className}>
    <p>
      <span>{props.title}</span>
      {props.onDeleteClicked && props.deleteText && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className="delete" onClick={props.onDeleteClicked}>
          {props.deleteText}
        </a>
      )}
    </p>
    {props.rows && (
      <ul>
        {props.rows.map((row) => (
          <li key={row.key}>
            <div>
              <span className="detail">
                {row.text}
                {row.links?.map((link) => (
                  <Link {...link} />
                ))}
              </span>
              {row.onDeleteClicked && row.deleteText && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className="delete" onClick={row.onDeleteClicked}>
                  {row.deleteText}
                </a>
              )}
            </div>
          </li>
        ))}
      </ul>
    )}
  </blockquote>
);

/* ------------------- Style ------------------- */
const StyledUi: React.FCX<Props> = styled(Ui)`
  // reset to user agent stylesheet
  margin: 0;

  padding: ${(props) => `${props.theme.spacing(1)}px ${props.theme.spacing(2)}px;`};
  border-left: 5px solid ${(props) => (props.type === 'warn' ? '#ffe564' : '#f87d7d')};
  background-color: ${(props) => (props.type === 'warn' ? '#FEFADB' : '#f2dede')};
  ${(props) =>
    props.maxHeight &&
    `
    max-height: ${props.maxHeight}px;
    overflow: auto;
  `}

  > p {
    margin: 0;
    display: flex;
    span {
      flex: 1;
    }
  }

  > ul {
    margin-top: ${(props) => props.theme.spacing(1)}px;
    margin-bottom: 0px;
    padding-left: ${(props) => props.theme.spacing(3)}px;

    li {
      &:hover {
        background-color: ${(props) => (props.type === 'warn' ? '#f8eebd' : '#f2c2c2')};
      }

      > div {
        display: flex;

        .detail {
          flex: 1;
          a {
            margin-left: ${(props) => props.theme.spacing(1)}px;
          }
        }
      }
    }
  }

  .delete {
    text-decoration: underline;
    cursor: pointer;
    color: #a94442;
  }
`;

/*---------------------------------------------- */
export default StyledUi;

import { useEffect } from 'react';
import { GridReadyEvent } from '@ag-grid-enterprise/all-modules';
import { Progress } from './api_request';

export const useToggleLoadingIcon = (gridApi: undefined | GridReadyEvent, progress?: Progress) =>
  useEffect(() => {
    if (progress === 'loading') {
      gridApi?.api.showLoadingOverlay();
    } else {
      gridApi?.api.hideOverlay();
    }
  }, [gridApi, progress]);

import axios from 'axios';
import { Camelize } from '~/libs';

type Data = {
  id: number;
  name: string;
  purposeType: string;
};

export type GetResponse = Data[];

export const getRequest = async () => {
  const response = await axios.get('/api/purposes');

  return Camelize.camelizeKeysDeep(response.data) as GetResponse;
};

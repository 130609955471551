import isString from 'lodash/isString';
import _isObject from 'lodash/isObject';
import { PlainObject } from 'src/types';

export const keys = <T extends {}>(o: T) => Object.keys(o) as (keyof typeof o)[];

export const rejectEmptyValues = <T extends object>(obj: PlainObject<T>): Partial<PlainObject<T>> =>
  keys(obj).reduce((result, key) => {
    const v = obj[key];
    const isEmptyValue = (isString(v) || Array.isArray(v)) && v.length === 0;

    if (!isEmptyValue) {
      result[key] = v;
    }

    return result;
  }, {} as Partial<PlainObject<T>>);

export const isObject = (arg: unknown): arg is { [key: string]: unknown } =>
  arg != null && _isObject(arg);

export function mappedObject<T, R = string[]>(
  mapping: T,
  targetValues: unknown
): Record<keyof T, R>;
export function mappedObject<T, R>(
  mapping: T,
  targetValues: unknown,
  callback: (pickedValue: unknown) => R
): Record<keyof T, R>;
export function mappedObject<T, R>(
  mapping: T,
  targetValues: unknown,
  callback?: (pickedValue: unknown) => R
) {
  const values = isObject(targetValues) ? targetValues : {};

  return keys(mapping).reduce((result, key) => {
    const value = (() => {
      const pickKey = String(mapping[key]);
      const pickedValue = values[pickKey];

      if (callback) {
        return callback(pickedValue);
      }

      if (Array.isArray(pickedValue)) {
        return pickedValue.map((v) => String(v));
      }

      if (typeof pickedValue === 'number') {
        return [String(pickedValue)];
      }

      if (typeof pickedValue === 'string') {
        return [pickedValue];
      }

      return [];
    })();

    return { ...result, [key]: value };
  }, {} as Record<keyof T, R>);
}

export const isExistsKey = <T>(data: T, key: string | number | symbol): key is keyof T =>
  key in data;

export const initializeOrAppendArrayMap = function <K extends string | number | symbol, T>(
  target: Map<K, T[]>,
  key: K,
  appendValue: T
) {
  const currentVal = target.get(key);
  if (!currentVal) {
    target.set(key, [appendValue]);
  } else {
    target.set(key, currentVal.concat(appendValue));
  }
};

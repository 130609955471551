import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import qs from 'qs';
import { Obj } from '~/libs';

type ReturnValue<T, T2> = Record<keyof T, string[]> | Record<keyof T2, string[]>;

export function useExtractCookieAndQueryString<T1, T2, R>(
  cookieMapps: T1,
  queryMapps: T2,
  customValueFn: (v: ReturnValue<T1, T2>, queryParams: unknown, cookieValues: unknown) => R
): R;

export function useExtractCookieAndQueryString<T1, T2>(
  cookieMapps: T1,
  queryMapps: T2
): ReturnValue<T1, T2>;

export function useExtractCookieAndQueryString<T1, T2, R>(
  cookieMapps: T1,
  queryMapps: T2,
  customValueFn?: (v: ReturnValue<T1, T2>, queryParams: unknown, cookieValues: unknown) => R
) {
  const { location } = useHistory();
  const queryParams = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );

  const [cookies] = useCookies([]);

  return useMemo(() => {
    const isUsingCookieValue = Obj.isObject(queryParams) && Object.keys(queryParams).length === 0;
    const mappedObj = isUsingCookieValue
      ? Obj.mappedObject(cookieMapps, cookies)
      : Obj.mappedObject(queryMapps, queryParams);

    return customValueFn ? customValueFn(mappedObj, queryParams, cookies) : mappedObj;
  }, [queryParams, cookieMapps, cookies, queryMapps, customValueFn]);
}

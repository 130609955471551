import { Constants } from '~/libs';

export const trackPageView = (pagePath: string, nicknameHash: string, unitName: string) => {
  if (!gtag || !Constants.env.GA_TRACKING_ID) {
    return;
  }

  gtag('config', Constants.env.GA_TRACKING_ID, {
    page_path: pagePath,
    custom_map: {
      dimension1: 'user',
      dimension2: 'unit',
    },
    user: nicknameHash,
    unit: unitName,
  });
};

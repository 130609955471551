import React, { ChangeEvent, ComponentProps, createRef, Ref, RefObject } from 'react';
import { ICellEditorReactComp } from '@ag-grid-community/react';
import { ICellEditorParams } from '@ag-grid-enterprise/all-modules';
import styled from 'styled-components';

/* ------------------- Style ------------------- */
const StyledUi: React.ComponentType<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  padding: 4px;
`;

/* ----------------- Container ----------------- */
type ValueType = string;

type ValueCallback<T, T2> = T2 extends ValueType
  ? { castValue?: never; onValueChanged?: (value: T) => void } // T2 が stringの場合は castValueは不要
  : { castValue: (value: ValueType) => T; onValueChanged?: (value: T) => void }; // T2 が string以外の場合は castValueを必須にする

type ContainerProps<T> = ICellEditorParams & {
  isFocusNextCellAfterEdit?: boolean;
  inputProps: ComponentProps<typeof StyledUi>;
  ref?: Ref<Container<T>>;
} & ValueCallback<T, T>;
/**
 * T を2つ渡すところが注意点. T が union type だと ValueCallback の conditional typeが union typeを分解しそれぞれで型を作り出そうとしてしまう．
 * e.g. T = number | null だった場合
 *   castValue の型は (value: ValueType) => number | (value: ValueType) => null と導出される
 *   本来 castValue の型は (value: ValueType) => number | null となってほしいので
 *
 *   Tをもう一個同じものを渡す. そうするT2のunion typeが分解されてそれぞれで型が作られるから
 *   castValue の型は ((value: ValueType) => number | null) | ((value: ValueType) => number | null) となる
 *
 *   そのあと重複しているunion typeはtsによって重複が取り除かれるので                                                                                            /
 *   (value: ValueType) => number | null となる
 */

class Container<T>
  extends React.PureComponent<ContainerProps<T>, { inputValue: ValueType }>
  implements ICellEditorReactComp
{
  isFocusNextCellAfterEdit: boolean;
  textFieldRef: RefObject<HTMLInputElement>;

  constructor(props: ContainerProps<T>) {
    super(props);

    this.state = { inputValue: props.value != null ? String(props.value) : '' };
    this.isFocusNextCellAfterEdit =
      props.isFocusNextCellAfterEdit !== undefined ? props.isFocusNextCellAfterEdit : true;
    this.textFieldRef = createRef();
  }

  componentDidMount(): void {
    // inputの描画が完了されるまでラグがあるので多少待機してからfocusを呼ぶ
    setTimeout(() => {
      this.textFieldRef.current?.focus();
    }, 10);
  }

  render() {
    return (
      <StyledUi
        ref={this.textFieldRef}
        value={this.state.inputValue}
        onBlur={this.focusOut.bind(this)}
        onChange={this.onChange.bind(this)}
        {...this.props.inputProps}
      />
    );
  }

  onChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({ inputValue: event.target.value });
  }

  getValue() {
    return this.props.castValue
      ? this.props.castValue(this.state.inputValue)
      : // castValue が null の場合の inputValue が T(string) で確定しているので強制キャスト(仕様変更があって型が変化した時このコードはruntimeエラーを発生する可能性あり
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.state.inputValue as any as T);
  }

  focusOut() {
    this.props.onValueChanged?.(this.getValue());
    this.props.stopEditing(!this.isFocusNextCellAfterEdit);
  }
}

/*---------------------------------------------- */
export type TextfieldCellEditorProps<T = string> = ContainerProps<T>;

export type TextFieldCellEditorParams<T = string> = Pick<
  ContainerProps<T>,
  'isFocusNextCellAfterEdit' | 'inputProps' | 'castValue' | 'onValueChanged'
>;

export default Container;

export const env = {
  ENTERPRISE_AG_GRID_LICENSE_KEY: process.env.REACT_APP_ENTERPRISE_AG_GRID_LICENSE_KEY || '',
  GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID || undefined,
};

const loginAndLogoutPathBase =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BACKEND_HOST_FOR_DEV
    : process.env.PUBLIC_URL;

export const externalRoutes = {
  fourT: 'https://s4t.jp/overview/#/2371519975/report',
  fourF: 'https://4facebook.strblt.me',
  fourG: 'https://4google.strblt.me',
  fourY: 'https://4yahoo.strblt.me',
  fourA: 'https://4apple.strblt.me',
  extension: 'https://pt.strblt.me',
  login: `${loginAndLogoutPathBase}/users/auth/ldap`,
  logout: `${loginAndLogoutPathBase}/users/sign_out`,
  tableau: 'https://tableau.adways.net',
  skuid: 'https://portal.sku.id/users/sign_in',
  help: 'https://drive.google.com/drive/u/0/folders/13wuxx_PRIZYhEL4MnhIt_RhBFv-j--bK',
};

export const multiCvRange = Array.from({ length: 10 }, (v, k) => k + 1);

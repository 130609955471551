import React, { ComponentProps, ReactNode } from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { ToggleLabel, DataTable, ErrorLabel } from '../fragments';

/* -------------------- DOM -------------------- */
type Props = ComponentProps<typeof DataTable> & {
  toggleTxt: string;
  toggleState: boolean;
  changeToggleState: () => void;
  onCsvDownloadClicked?: () => void;
  reportMinHeight?: number;
  customActions?: ReactNode;
};

const Ui: React.FCX<Props> = ({
  toggleTxt,
  toggleState,
  changeToggleState,
  onCsvDownloadClicked,
  loadingOverlayComponentFramework = loading,
  rowHeight = 35,
  scrollbarWidth = 15,
  reportMinHeight,
  className,
  customActions,
  ...rest
}) => (
  <>
    <Grid container className={`${className} header-container`}>
      <Grid item xs={6} sm={6} className="toggle">
        <ToggleLabel toggleState={toggleState} text={toggleTxt} onClick={changeToggleState} />
      </Grid>
      <Grid item xs={6} sm={6} className={`${!toggleState ? 'hide' : ''} actions`}>
        {customActions}
        {onCsvDownloadClicked && (
          <Button variant="contained" color="secondary" onClick={onCsvDownloadClicked} size="small">
            CSV Download
          </Button>
        )}
      </Grid>
    </Grid>
    <div className={`${className} ${!toggleState ? 'hide' : ''} datatable-container`}>
      <DataTable
        {...rest}
        loadingOverlayComponentFramework={loadingOverlayComponentFramework}
        rowHeight={rowHeight}
        scrollbarWidth={scrollbarWidth}
      />
    </div>
  </>
);

const loading = () => <CircularProgress />;

const ReportErrorRenderer: React.FCX<
  Pick<ComponentProps<typeof ErrorLabel>, 'text' | 'justifyContent'>
> = ({ justifyContent = 'flex-end', ...rest }) => (
  <ErrorLabel className="error-label" justifyContent={justifyContent} {...rest} />
);

/* ------------------- Style ------------------- */
const StyledUi: React.FCX<Props> = styled(Ui)`
  &.header-container {
    .toggle {
      display: flex;
      align-items: flex-end;
    }

    .hide {
      display: none;
    }

    .actions {
      text-align: right;

      button {
        padding-top: 1px;
        padding-bottom: 1px;

        &:last-child {
          margin-left: ${(props) => props.theme.spacing(1)}px;
        }
      }
    }
  }

  &.datatable-container {
    &.hide {
      display: none;
    }

    margin-top: ${(props) => props.theme.spacing(0.5)}px;
    display: flex;
    flex: 1;

    > div {
      width: 100%;
      ${(props) => props.reportMinHeight && `min-height: ${props.reportMinHeight}px;`}

      .error-label {
        svg {
          color: ${(props) => props.theme.palette.error.main};
        }
      }
    }
  }
`;

/*---------------------------------------------- */
export { ReportErrorRenderer };
export default StyledUi;

import { DeepPartial, PayloadAction } from '@reduxjs/toolkit';
import mergeWith from 'lodash/mergeWith';

export const createSetter =
  <T>() =>
  (state: T, action: PayloadAction<DeepPartial<T>>) =>
    mergeWith(state, action.payload, (org: unknown, update: unknown) => {
      if (Array.isArray(org) && Array.isArray(update)) {
        return update;
      }
    });

export const changeRoute = 'CHANGE_ROUTE';

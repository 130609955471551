import { useCallback, useState } from 'react';

export const useToggleState = (initialValue: boolean) => {
  const [isMobileDrawerOpen, setMobileDrawer] = useState(initialValue);
  const toggleMobileDrawer = useCallback(() => {
    setMobileDrawer((v) => !v);
  }, []);

  return [isMobileDrawerOpen, toggleMobileDrawer] as const;
};

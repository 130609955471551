import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledUi = styled.div<{ size: number; children?: ReactNode }>`
  ${(props) =>
    props.children != null
      ? // 子コンポーネントがnullを返す場合スペースだけ開いてしまうので :empty を使って子要素が空か判定してスペースが開かないようにする
        `
         margin-bottom: ${props.theme.spacing(props.size)}px;
         :empty {
           display: none;
         }
        `
      : `margin-top: ${props.theme.spacing(props.size)}px;`}
`;

export default StyledUi;

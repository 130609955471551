import React, { ComponentProps, useCallback, useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, DateRangePickerShape, FocusedInputShape } from 'react-dates';
import styled from 'styled-components';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import moment from 'moment';
import FormErrorMessages from './FormErrorMessages';

/* -------------------- DOM -------------------- */
type Props = DateRangePickerShape & {
  error?: boolean;
  errorMessages?: string[];
};

const Ui: React.FCX<Props> = ({ error, errorMessages, className, ...rest }) => (
  <div className={className}>
    {/* react-dates自体が少し大きいのでreact-datesにはsmallを固定で渡す*/}
    <DateRangePicker {...rest} small />
    {error && errorMessages != null && <FormErrorMessages messages={errorMessages} />}
  </div>
);

type OnDateChangedArgs = Parameters<DateRangePickerShape['onDatesChange']>[0];
type PresetDatesPulldownProps = Required<
  Pick<Props, 'onDatesChange'> & {
    presets?: Array<OnDateChangedArgs & { label: string }>;
  }
>;

const PresetDatesPulldown: React.FCX<PresetDatesPulldownProps> = (props) => (
  <div className="preset-dates">
    <FormControl>
      <InputLabel>Presets</InputLabel>
      {/* NOTE: nativeのプルダウンで実装しないとプルダウン押下時にカレンダーのblurが走ってしまいカレンダーが閉じてしまうので一旦nativeのプルダウンで実装 */}
      <Select
        native
        onChange={(e) => {
          const preset = props.presets[Number(e.target.value)];
          preset && props.onDatesChange(preset);
        }}
      >
        <option value="" />
        {props.presets.map((v, index) => (
          <option key={index} value={index}>
            {v.label}
          </option>
        ))}
      </Select>
    </FormControl>
  </div>
);

/* ------------------- Style ------------------- */
const StyledUi = styled(Ui)`
  .DateRangePicker {
    > div {
      .DateRangePickerInput__withBorder {
        border: 1px solid
          ${(props) => (props.error ? props.theme.palette.error.main : 'rgba(0, 0, 0, 0.23)')};
        border-radius: ${(props) => props.theme.shape.borderRadius}px;
        padding: 1px;
      }

      .DateRangePickerInput__block {
        display: flex;
        min-height: ${(props) => (props.small ? 30 : 40)}px;
        align-items: center;

        .DateInput {
          flex: 1;
          input {
            font: inherit;
            text-align: center;
            color: ${(props) => props.theme.palette.text.primary};
          }

          .DateInput_input__focused {
            border-color: ${(props) =>
              props.error ? props.theme.palette.error.main : props.theme.palette.primary.main};
          }

          ${(props) =>
            props.small &&
            `
            .DateInput_input__small {
              padding: 4px 7px 0px;
            }`}
        }
      }
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: ${(props) => props.theme.palette.primary.main};
      border-color: ${(props) => props.theme.palette.primary.dark};
    }

    .CalendarDay__selected_span {
      background: ${(props) => props.theme.palette.primary.light};
      border-color: ${(props) => props.theme.palette.primary.dark};
    }

    .preset-dates {
      padding: 0 22px 11px 22px;
      > div {
        min-width: 120px;
      }
    }
  }
`;

/* ----------------- Container ----------------- */
// prettier-ignore
type SupportDefaultProps = 'startDateId' | 'endDateId' | 'focusedInput' | 'onFocusChange' | 'displayFormat';
type RequiredProps = Omit<Props, SupportDefaultProps>;
type OptionalProps = Partial<Pick<Props, SupportDefaultProps>>;

type ContainerProps = RequiredProps &
  OptionalProps &
  Partial<Pick<PresetDatesPulldownProps, 'presets'>>;

const Container: React.FCX<ContainerProps> = (props) => {
  const {
    startDateId = 'start_date',
    endDateId = 'end_date',
    displayFormat = 'YYYY-MM-DD',
    focusedInput = null,
    onFocusChange,
    onDatesChange,
    isOutsideRange,
    presets,
    renderCalendarInfo,
    ...rest
  } = props;

  const defaultIsOutsideRange = useCallback(() => false, []);
  const defaultRenderCalendarInfo = useCallback(
    () => (
      <PresetDatesPulldown presets={presets || defaultPresets()} onDatesChange={onDatesChange} />
    ),
    [onDatesChange, presets]
  );

  // propsでonFocusedChangeを渡さなかった場合にこれらの変数を利用する
  const [defaultFocusedInput, setDefaultFocusedInput] = useState<FocusedInputShape | null>(null);

  const defaultOnFocusChanged = useCallback(
    (arg: FocusedInputShape | null) => setDefaultFocusedInput(arg),
    []
  );

  const innerProps: ComponentProps<typeof StyledUi> = {
    ...rest,
    onDatesChange,
    focusedInput: onFocusChange ? focusedInput : defaultFocusedInput,
    onFocusChange: onFocusChange ? onFocusChange : defaultOnFocusChanged,
    endDateId,
    startDateId,
    displayFormat,
    isOutsideRange: isOutsideRange !== undefined ? isOutsideRange : defaultIsOutsideRange,
    renderCalendarInfo:
      renderCalendarInfo !== undefined ? renderCalendarInfo : defaultRenderCalendarInfo,
  };

  return <StyledUi {...innerProps} />;
};

const defaultPresets = (): NonNullable<ContainerProps['presets']> => {
  const today = moment();
  const yesterday = moment().add(-1, 'day');
  const last7Days = moment().add(-6, 'day');
  const last30Days = moment().add(-29, 'day');
  const lastMonth = moment().add(-1, 'month');
  const last3Month = moment().add(-2, 'month');
  const last6Month = moment().add(-5, 'month');

  return [
    { startDate: today, endDate: today, label: 'today' },
    { startDate: yesterday, endDate: yesterday, label: 'yesterday' },
    { startDate: last7Days, endDate: today, label: 'last 7 days' },
    { startDate: last30Days, endDate: today, label: 'last 30 days' },
    {
      startDate: moment(today).startOf('month'),
      endDate: moment(today).endOf('month'),
      label: 'this month',
    },
    {
      startDate: moment(lastMonth).startOf('month'),
      endDate: moment(lastMonth).endOf('month'),
      label: 'last month',
    },
    {
      startDate: moment(last3Month).startOf('month'),
      endDate: today,
      label: 'last 3 month',
    },
    {
      startDate: moment(last6Month).startOf('month'),
      endDate: today,
      label: 'last 6 month',
    },
  ];
};

/*---------------------------------------------- */
export default Container;

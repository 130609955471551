import React, { createContext, useCallback } from 'react';
import { useAuthCheck, useApiRequest } from '~/libs';
import { GetResponse, getRequest } from '~/libs/api/users/myself';

export type State = {
  auth: ReturnType<typeof useAuthCheck>;
  userInfo: GetResponse | undefined;
};

const initialState: State = {
  auth: 'pending',
  userInfo: undefined,
};

const userSessionStore = createContext(initialState);

const { Provider } = userSessionStore;
const SessionStateProvider = ({ children }: { children: React.ReactChild }) => {
  const auth = useAuthCheck();

  const { data: userInfo } = useApiRequest<undefined | GetResponse>(
    undefined,
    useCallback(() => {
      if (auth === 'authorized') {
        // NOTE: ログインチェックのレスポンスが返ってきたらAPIを実行する
        return getRequest;
      }
    }, [auth])
  );

  return <Provider value={{ auth, userInfo }}>{children}</Provider>;
};

export { userSessionStore, SessionStateProvider };

export const file = (data: BlobPart[], type: string, fileName: string): void => {
  const blob = new Blob(data, { type: type });
  const link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(blob));
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const csv = (data: string, fileName: string): void => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  file([bom, data], 'text/csv', fileName);
};

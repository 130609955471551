import React, { forwardRef } from 'react';
import ReactSelect, { components } from 'react-select';
import { Props as ReactSelectProps } from 'react-select/src/Select';
import { OptionProps } from 'react-select/src/components/Option';
import FormErrorMessages from '../FormErrorMessages';
import { ToOnlyKnownKeysObj } from '~/types';

// ToOnlyKnownKeysObj を使って ReactSelectProps が継承している SelectComponentsProps の { [key: string]: any } を取り除いている
type StrictReactSelectProps<T, IsMulti extends boolean = false> = ToOnlyKnownKeysObj<
  ReactSelectProps<T, IsMulti>
>;

type Props<T, IsMulti extends boolean = false> = StrictReactSelectProps<T, IsMulti> & {
  ref?: React.Ref<ReactSelect<T, IsMulti>>;
  withCheckbox?: boolean;
  error?: boolean;
  errorMessages?: string[];
};

const Ui = forwardRef(function <T, IsMulti extends boolean = false>(
  { components, ...rest }: Omit<Props<T, IsMulti>, 'ref'>,
  ref: Props<T, IsMulti>['ref']
) {
  return (
    <>
      <ReactSelect
        {...rest}
        ref={ref}
        components={{
          ...components,
          ...(rest.withCheckbox ? { Option: Checkbox } : {}),
        }}
      />
      {rest.error && rest.errorMessages != null && (
        <FormErrorMessages messages={rest.errorMessages} />
      )}
    </>
  );
});

const Checkbox = React.memo(
  function <T, IsMulti extends boolean = false>(props: OptionProps<T, IsMulti>) {
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          aria-label={props.label}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    );
  },
  (p, n) => p.isFocused === n.isFocused && p.isSelected === n.isSelected
) as <T, IsMulti extends boolean = false>(props: OptionProps<T, IsMulti>) => JSX.Element;

export type UiProps<T, IsMulti extends boolean = false> = Props<T, IsMulti>;
// NOTE: forwardRef で包むとpropsの型が推論されないので型が効くようにキャスト
export default Ui as <T, IsMulti extends boolean = false>(props: Props<T, IsMulti>) => JSX.Element;

import axios from 'axios';
import { Camelize } from '~/libs';

export type GetResponse = {
  id: number;
  nickname: string;
  unitName: string;
  postName: string;
  isAdmin: boolean;
  nicknameHash: string;
};

export const getRequest = async () => {
  const response = await axios.get('/api/users/myself.json');

  return Camelize.camelizeKeysDeep(response.data) as GetResponse;
};

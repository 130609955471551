import { createSlice } from '@reduxjs/toolkit';
import { createSetter, changeRoute } from '~/state/redux_store_utils';

type Data = {
  label: string;
  value: string;
};

type SelectedState = {
  promotion?: Data;
  ym: string;
};

export type State = SelectedState & {
  fetchConditions?: SelectedState;
};

const initialState = (): State => ({
  ym: '',
});

const setter = createSetter<State>();

const slice = createSlice({
  name: 'filter/configuration',
  initialState: initialState(),
  reducers: {
    setWithFetchConditions: (state, action: Parameters<typeof setter>[1]) => {
      setter(state, action);

      const { fetchConditions, ...rest } = state;
      state.fetchConditions = rest;
    },
  },
  extraReducers: {
    [changeRoute]: () => initialState(),
  },
});

export default slice;

import { ProcessCellForExportParams } from '@ag-grid-enterprise/all-modules';

type CsvExportParamsType = {
  allColumns: boolean;
  fileName: string;
  columnGroups: boolean;
  columnSeparator: string;
  onlySelected: boolean;
  skipHeader: boolean;
  skipFooters: boolean;
  skipGroups: boolean;
  skipFloatingTop: boolean;
  skipFloatingBottom: boolean;
  skipPinnedTop: boolean;
  suppressQuotes: boolean;
  processCellCallback?: (args: ProcessCellForExportParams) => string;
};

export const csvExportParams = (params: Partial<CsvExportParamsType>) => {
  const defaultParams = {
    allColumns: false,
    fileName: 'NoName.csv',
    columnGroups: true,
    columnSeparator: ',',
    onlySelected: false,
    skipHeader: false,
    skipFooters: true,
    skipGroups: false,
    skipFloatingTop: false,
    skipFloatingBottom: true,
    skipPinnedTop: false,
    suppressQuotes: false,
    processCellCallback: undefined,
  };

  return { ...defaultParams, ...params };
};

import React from 'react';
import { Error } from '@material-ui/icons';
import styled from 'styled-components';

/* -------------------- DOM -------------------- */
type Props = {
  text: string | number;
};

const Ui: React.FCX<Props> = (props) => (
  <div className={props.className}>
    <Error /> {props.text}
  </div>
);

/* ------------------- Style ------------------- */
type StyledUiProps = Props & {
  color?: string;
  iconSize?: number;
  justifyContent?: string;
};

const StyledUi: React.FCX<StyledUiProps> = styled<React.FCX<StyledUiProps>>(
  ({ text, className }) => <Ui className={className} text={text} />
)`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'normal'};
  > svg {
    font-size: ${(props) => props.iconSize || 17}px;
    ${(props) => props.color && `color: ${props.color};`}
  }
`;

/*---------------------------------------------- */
export default StyledUi;

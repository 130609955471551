import { useCallback } from 'react';
import { useApiRequest } from './api_request';
import { getRequest } from '~/libs/api/auth';

export const useAuthCheck = () => {
  const state = useApiRequest(
    false,
    useCallback(() => getRequest, [])
  );

  if (state.progress !== 'success') {
    return 'pending';
  }

  return state.data ? 'authorized' : 'unauthorized';
};

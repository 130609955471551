import React, { forwardRef, useCallback, useMemo } from 'react';
import { ActionMeta, SelectComponentsConfig, Theme } from 'react-select';
import { OptionsType, ValueType } from 'react-select/src/types';
import { useTheme } from '@material-ui/core/styles';
import PresentationalComponent, { UiProps } from './Ui';
import pulldownEllipsisValueContainerCreator from './EllipsisValueContainerCreator';
import pulldownVirtualizedMenuListCreator from './VirtualizedMenuListCreator';
import { OptionTypeDefault } from '~/types';

export type SelectAllOptType = OptionTypeDefault & { isSelectAll: boolean };

type NormalModeProps<T, IsMulti extends boolean = false> = Omit<
  UiProps<T, IsMulti>,
  'ref' | 'theme' | 'value'
> & {
  // 4系から value の型定義が IsMulti によって配列 or 非配列に切り替わらないようになってしまったので切り替わるように上書き
  value?: ValueType<T, IsMulti>;
  size?: 'small' | 'medium';
  withCheckbox?: boolean;
  error?: boolean;
  errorMessages?: string[];
};

type WithSelectAllModeProps<T, IsMulti extends boolean = false> = Omit<
  NormalModeProps<T, IsMulti>,
  'options'
> & {
  options?: OptionsType<T>;
  components?: SelectComponentsConfig<T | SelectAllOptType, IsMulti>;
  withSelectAll: true;
  selectAllLabel?: { label?: string; value?: string };
};

type Props<T, IsMulti extends boolean = false> =
  | NormalModeProps<T, IsMulti>
  | WithSelectAllModeProps<T, IsMulti>;
type RefProps<T, IsMulti extends boolean = false> = Pick<UiProps<T, IsMulti>, 'ref'>;
type Ref<T, IsMulti extends boolean = false> = RefProps<T, IsMulti>['ref'];

const Container = forwardRef(function <T, IsMulti extends boolean = false>(
  props: Props<T, IsMulti>,
  ref: Ref<T, IsMulti>
) {
  const commonProps = {
    theme: useApplyMaterialUITheme(props.error),
    styles: useCustomStyle(props.size, props.styles),
  };

  if (!('withSelectAll' in props)) {
    return <PresentationalComponent {...props} {...commonProps} ref={ref} />;
  }

  return <WithSelectAllContainer {...props} {...commonProps} ref={ref} />;
});

const WithSelectAllContainer = forwardRef(function <T, IsMulti extends boolean = false>(
  props: WithSelectAllModeProps<T, IsMulti>,
  ref: Ref<T, IsMulti>
) {
  const selectAllElement = useCreateSelectAllElement(props.selectAllLabel);
  const isSelectAllChecked = (() => {
    if (!props.options || !Array.isArray(props.value) || props.value.length === 0) {
      return false;
    }
    return props.options.length === props.value.length;
  })();

  const { options, onChange, value, isMulti } = props;

  const innerProps: UiProps<T | SelectAllOptType, IsMulti> = {
    ...(props as UiProps<T | SelectAllOptType, IsMulti>),
    isMulti,
    ref: ref as UiProps<T | SelectAllOptType, IsMulti>['ref'],
    value: useMemo(
      () =>
        isSelectAllChecked && Array.isArray(value) && isMulti
          ? ([selectAllElement, ...value] as ValueType<T, true> as ValueType<T, IsMulti>)
          : value,
      [isSelectAllChecked, value, selectAllElement, isMulti]
    ),
    options: useMemo(() => [selectAllElement, ...(options || [])], [options, selectAllElement]),
    onChange: useCallback<NonNullable<UiProps<T | SelectAllOptType, IsMulti>['onChange']>>(
      (selected, event) => {
        if (onChange && options) {
          const meta: ActionMeta<T> = (() => {
            if ('removedValue' in event) {
              const { removedValue, ...restEvent } = event;
              return {
                ...restEvent,
                // NOTE: option, removedValue は型を合わせるために SelectAllType を間引いて T | undefined にする
                removedValue: !('isSelectAll' in removedValue) ? removedValue : ({} as T),
              };
            }

            if ('option' in event) {
              const { option, ...restEvent } = event;
              return {
                ...restEvent,
                // NOTE: option, removedValue は型を合わせるために SelectAllType を間引いて T | undefined にする
                option: option != null && !('isSelectAll' in option) ? option : undefined,
              };
            }

            if ('removedValues' in event) {
              const { removedValues, ...restEvent } = event;
              return {
                ...restEvent,
                removedValues: removedValues.map((v) => {
                  return 'isSelectAll' in v ? ({} as T) : v;
                }),
              };
            }

            return event;
          })();

          onChange(getSelectedValues(selected, event, options, isMulti), meta);
        }
      },
      [options, onChange, isMulti]
    ),
  };

  return <PresentationalComponent {...innerProps} />;
}) as <T, IsMulti extends boolean = false>(
  props: WithSelectAllModeProps<T, IsMulti> & RefProps<T, IsMulti>
) => JSX.Element;

const useCustomStyle = function <T, IsMulti extends boolean = false>(
  size: Props<T, IsMulti>['size'],
  styles?: Props<T, IsMulti>['styles']
): Props<T, IsMulti>['styles'] {
  return useMemo(() => {
    if (styles) {
      return styles;
    }

    if (size === 'small') {
      return {
        control: (base) => ({ ...base, minHeight: 30 }),
        input: (base) => ({ ...base, height: 20, marginTop: 0 }),
        multiValue: (base) => ({ ...base, marginTop: 0, marginBottom: 0 }),
        dropdownIndicator: (base) => ({ ...base, padding: 4 }),
        clearIndicator: (base) => ({ ...base, padding: 4 }),
      };
    }

    // defaultのサイズ
    return {
      control: (base) => ({ ...base, minHeight: 40 }),
    };
  }, [size, styles]);
};

const useApplyMaterialUITheme = (isError: boolean = false) => {
  const {
    palette: { primary, error },
  } = useTheme();

  const palette = isError ? error : primary;
  return useCallback(
    (theme: Theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        ...(isError
          ? { neutral20: palette.main, neutral30: palette.main, neutral50: palette.main }
          : {}),
        primary50: palette.light,
        primary25: palette.light,
        primary: palette.main,
      },
    }),
    [isError, palette]
  );
};

const useCreateSelectAllElement = function <T>(
  selectAll: WithSelectAllModeProps<T>['selectAllLabel']
) {
  const { label, value } = selectAll || {};

  return useMemo(
    () => ({
      label: label || 'select all',
      value: value || '*',
      isSelectAll: true,
    }),
    [label, value]
  );
};

const getSelectedValues = function <T, IsMulti extends boolean = false>(
  selected: ValueType<T | SelectAllOptType, IsMulti> | null,
  event: ActionMeta<T | SelectAllOptType>,
  options: OptionsType<T>,
  isMulti?: boolean
): ValueType<T, IsMulti> {
  if (Array.isArray(selected) && selected.length > 0 && isMulti) {
    // 直近で `全選択` にチェックを入れた
    if ('isSelectAll' in selected[selected.length - 1]) {
      // NOTE: 配列型の変数は 一回 ValueType<T, true> にキャストしてから
      // さらに ValueType<T, IsMulti> にキャストする（直接 ValueType<T, IsMulti> とはキャストできない
      return options as ValueType<T, true> as ValueType<T, IsMulti>;
    }

    // あと一つチェックを入れると全てチェック状態になる場合 or `全選択` も含め全てにチェックが入っている状態からどれか一つのチェックを外す
    if (selected.length === options.length) {
      if (event.action === 'select-option') {
        return options as ValueType<T, true> as ValueType<T, IsMulti>;
      }

      // `全選択` も含め全てにチェックが入っている状態からどれか一つのチェックを外す
      if (selected.find((v) => 'isSelectAll' in v)) {
        //  - まだ `全選択` がチェックされている場合 つまり `全選択` 以外の何かを外した
        // `全選択` のチェック状態も解除する
        return selected.filter((option): option is T => !('isSelectAll' in option)) as ValueType<
          T,
          true
        > as ValueType<T, IsMulti>;
      }

      //  - `全選択` を外した
      return [] as ValueType<T, true> as ValueType<T, IsMulti>;
    }
  }

  // NOTE: ValueType<T| SelectAllOptType> だが処理の流れ上 `全選択` が含まれない形で返すのでキャスト
  // より堅牢にするなら selected.filter((option): option is T => !('isSelectAll' in option) をするべきなんだけど型の安全をとるためだけにこれをするのはオーバヘッドなのでキャストしてます
  return selected as ValueType<T, IsMulti>;
};

export type PulldownProps<T, IsMulti extends boolean = false> = Props<T, IsMulti> &
  RefProps<T, IsMulti>;
export { pulldownEllipsisValueContainerCreator, pulldownVirtualizedMenuListCreator };

// NOTE: forwardRef で包むとpropsの型が推論されないので型が効くようにキャスト
declare function _Container<T, IsMulti extends boolean = false>(
  props: NormalModeProps<T, IsMulti> & RefProps<T, IsMulti>
): JSX.Element;
declare function _Container<T, IsMulti extends boolean = false>(
  props: WithSelectAllModeProps<T, IsMulti> & RefProps<T, IsMulti>
): JSX.Element;
export default Container as typeof _Container;

import React from 'react';
import { createMuiTheme, Theme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

export type ThemeProps = { theme: Theme };

export const main = createMuiTheme({
  palette: {
    primary: {
      main: '#2196F3',
      dark: '#1976D2',
      light: '#BBDEFB',
    },
    secondary: {
      main: '#FFC107',
    },
    text: {
      primary: '#212121',
      secondary: '#757575',
    },
  },
});

export const dark = createMuiTheme({
  palette: {
    background: {
      default: '#3e464c',
      paper: '#3e464c',
    },
    primary: {
      main: '#333333',
      dark: '#000',
      light: '#696969',
    },
    divider: '#fff',
    text: {
      primary: '#fff',
      secondary: '#ccc',
    },
  },
});

export const ThemeProvider = (props: { theme: Theme; children?: React.ReactChild }) => (
  <MuiThemeProvider theme={props.theme || {}}>
    <StyledThemeProvider theme={props.theme}>{props.children}</StyledThemeProvider>
  </MuiThemeProvider>
);

export const chartTheme = {
  blue: 'rgba(0, 104, 183, 0.8)',
  yellow: 'rgba(252, 200, 0, 0.8)',
  red: 'rgba(230, 0, 51, 0.5)',
  green: 'rgba(0, 153, 68, 0.5)',
};

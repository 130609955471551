import { isExistsKey } from '~/libs/object';

const locale = 'en-US';

// Intl.NumberFormat のインスタンスを毎回生成するのはコストなのでキャッシュできるようにする
const intlNumberFormatInstances: { [key: string]: Intl.NumberFormat } = {};
const getInstance = (
  identityString: string,
  ...arg: ConstructorParameters<typeof Intl.NumberFormat>
) => {
  if (intlNumberFormatInstances[identityString]) {
    return intlNumberFormatInstances[identityString];
  }

  const locale = arg[0];
  const opts = arg[1];
  return (intlNumberFormatInstances[identityString] = new Intl.NumberFormat(locale, opts));
};

// NOTE: angularのformatCurrency と互換性を保つためのマッピングオブジェクト
const customCurrencies = {
  Euro: { intlCode: 'EUR', replaceCurrency: { from: '€', to: 'Euro' } },
};

// NOTE: display, digits, locale の引数はそのニーズが出てきたら実装する
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatCurrency = (value: any, currencyCode: string) => {
  if (value == null || value === '') {
    return null;
  }

  const mappedCurrencyCode = isExistsKey(customCurrencies, currencyCode)
    ? customCurrencies[currencyCode].intlCode
    : currencyCode;

  const result = getInstance(`${locale}__currency__${currencyCode}`, locale, {
    style: 'currency',
    currency: mappedCurrencyCode,
  }).format(value);

  if (!isExistsKey(customCurrencies, currencyCode)) {
    return result;
  }

  const replaceCurrency = customCurrencies[currencyCode].replaceCurrency;
  return result.replace(replaceCurrency.from, replaceCurrency.to);
};

const parseDigits = (digits: string) => {
  const [minimumIntegerDigits, ...rest] = digits.split('.');
  const [minimumFractionDigits, maximumFractionDigits] = rest.join().split('-');

  return {
    minimumIntegerDigits: minimumIntegerDigits ? Number(minimumIntegerDigits) : undefined,
    minimumFractionDigits: minimumFractionDigits ? Number(minimumFractionDigits) : undefined,
    maximumFractionDigits: maximumFractionDigits ? Number(maximumFractionDigits) : undefined,
  };
};

// NOTE: locale の引数はそのニーズが出てきたら実装する
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatDecimal = (value: any, digits?: string) =>
  value == null || value === ''
    ? null
    : getInstance(`${locale}__decimal__${digits}`, locale, {
        ...parseDigits(digits || '1.0-3'),
      }).format(value);

// NOTE: locale の引数はそのニーズが出てきたら実装する
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatPercent = (value: any, digits?: string) =>
  value == null || value === ''
    ? null
    : getInstance(`${locale}__percent__${digits}`, locale, {
        style: 'percent',
        ...(digits ? parseDigits(digits) : {}),
      }).format(value);

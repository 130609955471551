import React from 'react';
import { components, Theme } from 'react-select';
import { MenuListComponentProps } from 'react-select/src/components/Menu';
import { List, ListRowProps, ListProps } from 'react-virtualized';
import styled, { ThemedStyledInterface } from 'styled-components';
import { ToOnlyKnownKeysObj } from '~/types';

/* -------------------- DOM -------------------- */
type Props<T, IsMulti extends boolean = false> = MenuListComponentProps<T, IsMulti>;

type ListPropsCreator = (
  nodes: React.ReactNode[]
) => Omit<ToOnlyKnownKeysObj<ListProps>, 'rowCount' | 'rowRenderer'>;

const uiCreator = function <T, IsMulti extends boolean = false>(
  listPropsCreator: ListPropsCreator
): React.ComponentType<Props<T, IsMulti>> {
  return ({ children, ...rest }) =>
    !Array.isArray(children) ? (
      <components.MenuList {...rest} children={children} />
    ) : (
      <List
        {...listPropsCreator(children)}
        className={rest.className}
        rowCount={children.length}
        rowRenderer={(rowProps: ListRowProps) => (
          <div key={rowProps.key} style={rowProps.style}>
            {children[rowProps.index]}
          </div>
        )}
      />
    );
};

/* ------------------- Style ------------------- */
const styledUiCreator = function <T, IsMulti extends boolean = false>(options?: {
  rowHeight?: number;
  openMenuRows?: number;
}): React.ComponentType<Props<T, IsMulti>> {
  const margin = 2;
  const rowHeight = options?.rowHeight || 36;
  const openMenuRows = options?.openMenuRows || 8;

  const VirtualizedMenuListComponent = uiCreator<T, IsMulti>((nodes) => ({
    height: Math.min(nodes.length, openMenuRows) * rowHeight + margin * 2,
    // NOTE: cssで横幅を100%にはするが必須のpropsなので最小値をダミーで渡す
    width: 1,
    rowHeight: rowHeight,
  }));

  // react-select のテーマしか渡ってこない（ src/theme.tsx で設定したテーマは渡ってこない. これは別途原因調査したい）ので
  // src/types/styled-component.d.ts で定義している DefaultTheme の型を上書きするためのキャスト
  const reactSelectThemeStyled = styled as unknown as ThemedStyledInterface<Theme>;

  return reactSelectThemeStyled(VirtualizedMenuListComponent)`
    margin-top: ${margin}px;
    margin-bottom: ${margin}px;
    // NOTE: AutoSizerを使うと上スクロールができない事象が発生するのでAutoSizerを使わずcssで横幅を100%にする
    width: 100% !important;
    > div {
      max-width: none !important;
      > div.row {
        height: ${rowHeight}px;
      }
    }
  ` as React.ComponentType<Props<T, IsMulti>>; // styled-components が生成する型は `theme` が optional なので必須になるようにキャスト
};

/*---------------------------------------------- */
export type VirtualizedMenuListProps<T, IsMulti extends boolean = true> = MenuListComponentProps<
  T,
  IsMulti
>;
export default styledUiCreator;

import React from 'react';

type Props = {
  labelProps?: JSX.IntrinsicElements['label'];
  inputProps?: JSX.IntrinsicElements['input'];
  labelText: string;
};

const Ui: React.FCX<Props> = (props) => (
  <label {...props.labelProps}>
    <input type="checkbox" {...props.inputProps} />
    {props.labelText}
  </label>
);

export default Ui;

import axios from 'axios';
import { Camelize } from '~/libs';

export type GetResponse = {
  adwaysUnitId: number;
  chatworkAccountId: number;
  chatworkRoomId: number;
  createdAt: string;
  employeeNumber: number;
  id: number;
  mailAddress: string;
  monthlyEmployeeId: number;
  name: string;
  updatedAt: string;
  userId: null;
};

export interface GetRequestParams {
  recentMonth: number;
}

export const getRequest = async (params: GetRequestParams) => {
  const response = await axios.get('/api/staffs', {
    params: Camelize.underscoreKeysDeep(params),
  });

  return Camelize.camelizeKeysDeep(response.data) as GetResponse[];
};

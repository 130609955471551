import React, { ReactNode } from 'react';
import { components } from 'react-select';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { SelectAllOptType } from './';

type Props<T, IsMulti extends boolean = false> = ValueContainerProps<T | SelectAllOptType, IsMulti>;

const containerCreator = function <T, IsMulti extends boolean = false>(
  labelUnit?: string
): React.FCX<Props<T | SelectAllOptType, IsMulti>> {
  return (props) => {
    const val = props.getValue();
    const itemLength = val.filter((v) => !('isSelectAll' in v)).length || 0;

    const children: React.ReactNode | React.ReactNodeArray =
      itemLength > 1
        ? [
            `${itemLength}${labelUnit ? labelUnit : ` items selected`}`,
            (props.children as ReactNode[])[1],
          ]
        : props.children;

    const innerProps = { ...props, children };

    return <components.ValueContainer {...innerProps} />;
  };
};

export type EllipsisValueContainerProps<T, IsMulti extends boolean = false> = Props<T, IsMulti>;
export default containerCreator;

import React from 'react';
import { Typography } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import { TreeItemProps } from '@material-ui/lab/TreeItem';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ThemeProps as TP } from '~/theme';

/* -------------------- DOM -------------------- */
type Props = TreeItemProps & {
  labelText: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  internalLink?: string;
  externalLink?: React.AnchorHTMLAttributes<HTMLAnchorElement>;
};

const Ui: React.FCX<Props> = ({ externalLink, internalLink, labelText, labelIcon, ...rest }) => (
  <TreeItem
    label={
      externalLink != null ? (
        <a {...externalLink}>
          <ItemContent labelText={labelText} labelIcon={labelIcon} />
        </a>
      ) : internalLink != null ? (
        <NavLink to={internalLink}>
          <ItemContent labelText={labelText} labelIcon={labelIcon} />
        </NavLink>
      ) : (
        <ItemContent labelText={labelText} labelIcon={labelIcon} />
      )
    }
    {...rest}
  />
);

const ItemContent: React.FCX<Pick<Props, 'labelText' | 'labelIcon'>> = ({
  labelText,
  labelIcon: LabelIcon,
}) => (
  <div className="item-content">
    {LabelIcon != null && <LabelIcon color="inherit" />}
    <Typography variant="body2">{labelText}</Typography>
  </div>
);

/* ------------------- Style ------------------- */
type StyledUiProps = Props & {
  bgColor?: (props: TP) => string;
  textColor?: (props: TP) => string;
};

const StyledUi: React.FCX<StyledUiProps> = styled(
  // NOTE: 丸ごとpropsを渡すとstyled-componentsのwarningがでるので 分割代入で必要なpropsだけに間引いて渡す
  ({ bgColor, textColor, ...rest }: StyledUiProps) => <Ui {...rest} />
)`
  color: ${(props) => props.theme.palette.text.secondary};

  a {
    color: inherit;
    text-decoration: inherit;
  }

  &:focus > div[class*='MuiTreeItem-content'],
  &.active > div[class*='MuiTreeItem-content'] {
    background-color: var(--tree-view-bg-color, ${(props) => props.theme.palette.grey['800']});
    color: var(--tree-view-color, ${(props) => props.theme.palette.text.primary});
  }

  div[class*='MuiTreeItem-iconContainer'] {
    margin-left: -1px;
    margin-right: 0px;
  }

  ${(props) => (props.bgColor ? `--tree-view-bg-color: ${props.bgColor(props)};` : '')}
  ${(props) => (props.textColor ? `--tree-view-color: ${props.textColor(props)};` : '')}

  .item-content {
    display: flex;
    align-items: center;
    padding: ${(props) => props.theme.spacing(0.5, 0)};

    p {
      margin-left: ${(props) => props.theme.spacing(0.5)}px;
    }
  }
`;

/*---------------------------------------------- */
export default React.memo<StyledUiProps>((props) => <StyledUi {...props} />);

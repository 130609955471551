import axios from 'axios';
import { Camelize } from '~/libs';

export type GetResponse = {
  id: number;
  name: string;
};

export interface GetRequestParams {
  recentMonth?: number;
  withoutSpend?: boolean;
  ym?: string;
}

export const getRequest = async (params: GetRequestParams) => {
  const response = await axios.get('/api/promotions', {
    params: Camelize.underscoreKeysDeep(params),
  });

  return Camelize.camelizeKeysDeep(response.data) as GetResponse[];
};

export type PostResponse = true;

export type PostRequestParams = {
  settings: Array<{ id: number; channelId: string }>;
};

export const postRequest = async (params: PostRequestParams) => {
  const response = await axios.post('/api/promotions', Camelize.underscoreKeysDeep(params));

  return Camelize.camelizeKeysDeep(response.data) as PostResponse;
};

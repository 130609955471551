import { createSlice } from '@reduxjs/toolkit';
import { createSetter, changeRoute } from '~/state/redux_store_utils';

type Data = {
  label: string;
  value: string;
};

type SelectedState = {
  promotions: Data[];
};

export type State = SelectedState & {
  fetchConditions?: SelectedState;
};

const initialState = (): State => ({
  promotions: [],
});

const slice = createSlice({
  name: 'filter/configuration_promotion_channel',
  initialState: initialState(),
  reducers: {
    set: createSetter<State>(),
    setFetchConditions: (state) => {
      const { fetchConditions, ...rest } = state;
      state.fetchConditions = rest;
    },
  },
  extraReducers: {
    [changeRoute]: () => initialState(),
  },
});

export default slice;

import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import * as Filter from './filter';

export { changeRoute } from './redux_store_utils';

export { Filter };

export const rootReducer = combineReducers({
  filter: combineReducers({
    overview: Filter.OverviewModule.reducer,
    summary: Filter.SummaryModule.reducer,
    notice: Filter.NoticeModule.reducer,
    configuration: Filter.ConfigurationModule.reducer,
    configurationMultiCv: Filter.ConfigurationMultiCvModule.reducer,
    configurationPromotionChannel: Filter.ConfigurationPromotionChannelModule.reducer,
    reportDownload: Filter.ReportDownloadModule.reducer,
  }),
});

export type RootState = ReturnType<typeof rootReducer>;

const reduxStore = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
});

export default reduxStore;

import { createSlice } from '@reduxjs/toolkit';
import { createSetter, changeRoute } from '~/state/redux_store_utils';

type Data = {
  label: string;
  value: string;
};

type SelectedState = {
  promotions: Data[];
  menus: Data[];
  devices: Data[];
  purposes: Data[];
  units: Data[];
  staffs: Data[];
  date: {
    startDate: string;
    endDate: string;
  };
};

export type State = SelectedState & {
  fetchConditions?: SelectedState;
};

const initialState = (): State => ({
  promotions: [],
  menus: [],
  devices: [],
  purposes: [],
  units: [],
  staffs: [],
  date: {
    startDate: '',
    endDate: '',
  },
});

const slice = createSlice({
  name: 'filter/summary',
  initialState: initialState(),
  reducers: {
    set: createSetter<State>(),
    setFetchConditions: (state) => {
      const { fetchConditions, ...rest } = state;
      state.fetchConditions = rest;
    },
  },
  extraReducers: {
    [changeRoute]: () => initialState(),
  },
});

export default slice;

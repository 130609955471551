import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = { [key: string]: any };

const isAnyObject = (arg: unknown): arg is AnyObject => typeof arg === 'object' && arg !== null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const camelizeKeysDeep = (value: unknown): any => {
  if (Array.isArray(value)) {
    return value.map((element) => camelizeKeysDeep(element));
  }

  if (isAnyObject(value)) {
    const camelizeKeysObject = {} as AnyObject;
    Object.keys(value).forEach(
      (key) => (camelizeKeysObject[camelCase(key)] = camelizeKeysDeep(value[key]))
    );
    return camelizeKeysObject;
  }

  return value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const underscoreKeysDeep = (value: unknown): any => {
  if (Array.isArray(value)) {
    return value.map((element) => underscoreKeysDeep(element));
  }

  if (isAnyObject(value)) {
    const underscoreKeysObject = {} as AnyObject;
    Object.keys(value).forEach(
      (key) => (underscoreKeysObject[snakeCase(key)] = underscoreKeysDeep(value[key]))
    );
    return underscoreKeysObject;
  }

  return value;
};

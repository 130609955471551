import React from 'react';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { LicenseManager } from '@ag-grid-enterprise/core';
import styled from 'styled-components';
import { Constants } from '~/libs';

LicenseManager.setLicenseKey(Constants.env.ENTERPRISE_AG_GRID_LICENSE_KEY);

/* -------------------- DOM -------------------- */
const Ui: React.FCX<AgGridReactProps> = (props) => (
  <div className={`${props.className} ag-theme-balham`}>
    <AgGridReact
      enableBrowserTooltips
      gridOptions={{
        defaultColDef: { sortable: true, filter: true, resizable: true },
      }}
      {...props}
      modules={AllModules}
    />
  </div>
);

/* ------------------- Style ------------------- */
const defaultRowHeight = 35;

const StyledUi: React.FCX<AgGridReactProps> = styled(Ui)`
  .MuiCircularProgress-root svg {
    color: ${(props) => props.theme.palette.primary.main};
  }

  // ↓ツールチップが隠れないようにする
  .ag-root-wrapper {
    overflow: visible;
  }

  .ag-menu {
    max-height: none;
  }
  // ↑ツールチップが隠れないようにする

  .ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper,
  .ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
    min-height: ${(props) => (props.rowHeight ? props.rowHeight : defaultRowHeight)}px;
  }

  .ag-floating-top .ag-row {
    &.bulk-input-row {
      background-color: rgba(230, 230, 230, 0.5);
    }
  }

  .ag-row {
    &.stop-status-row {
      background-color: #eee;
    }
  }

  .ag-row .ag-cell {
    line-height: ${(props) => (props.rowHeight ? props.rowHeight : defaultRowHeight)}px;

    &.ag-numeric-cell {
      font-family: Consolas, monospace;
    }

    &.text-danger {
      color: ${(props) => props.theme.palette.error.light};
    }

    &.ag-cell-bold {
      font-weight: bold;
    }

    &.ag-cell-inline-editing {
      line-height: normal;
      height: 100%;
      .ag-react-container {
        height: 100%;
      }
    }

    &.editable-cell {
      &:before {
        background-image: url(data:image/svg+xml,${() => {
          const size = 11;
          const color = '#ccc';
          const editableMarkSvg = `<?xml version="1.0" encoding="UTF-8"?>
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"  width="${size}" height="${size}" viewBox="0 0 24 24">
               <path fill="${color}" d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z" />
            </svg>`;

          return encodeURIComponent(editableMarkSvg);
        }});
        content: '　';
        background-repeat: no-repeat;
        position: absolute;
        left: 0px;
        width: 11px;
        height: 11px%;
      }
    }

    &.disallow-edit-cell {
      background-color: lightgray;
    }

    &.changed-value-cell {
      background-color: rgba(252, 248, 227, 0.7);
    }

    &.pre-wrap-cell {
      white-space: pre-wrap;
      line-height: normal;
    }
  }

  .ag-body-horizontal-scroll {
    display: ${(props) => (props.rowData != null && props.rowData.length ? 'inherit' : 'none')};
  }

  .ag-popup-editor {
    border-color: transparent;

    .ag-large-text {
      &:after {
        content: '改行は Shift + Enter';
        margin-left: 5px;
      }

      .ag-large-textarea {
        border: 1px solid #0091ea;
        textarea {
          border: none;
          padding: 11px;
        }
      }
    }
  }
`;

/*---------------------------------------------- */
export default StyledUi;

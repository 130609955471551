import { OptionsType } from 'react-select';
import { GroupTypeBase } from 'react-select/src/types';

type OptionIsArrayArg<T> = ReadonlyArray<T | GroupTypeBase<T>> | OptionsType<T> | undefined;

export const isOptionsType = <T>(arg: OptionIsArrayArg<T>): arg is OptionsType<T> => {
  if (Array.isArray(arg)) {
    const isGroupedOptionsType = arg[0] && 'options' in arg[0];
    return !isGroupedOptionsType;
  }

  return false;
};

import React from 'react';
import { ArrowDropDown, ArrowRight, SvgIconComponent } from '@material-ui/icons';
import styled from 'styled-components';

/* -------------------- DOM -------------------- */
type Props = {
  onClick?: () => void;
  text: string;
  icon: SvgIconComponent;
};

const Ui: React.FCX<Props> = ({ icon: Icon, ...rest }) => (
  <div className={rest.className}>
    <span onClick={rest.onClick}>
      <Icon />
      <label>{rest.text}</label>
    </span>
  </div>
);

/* ------------------- Style ------------------- */
const StyledUi = styled(Ui)`
  display: flex;
  > span {
    display: flex;

    > * {
      cursor: pointer;
    }

    label {
      display: flex;
      align-items: center;
    }
  }
`;

/* ----------------- Container ----------------- */
type ContainerProps = Omit<Props, 'icon'> & {
  openIcon?: SvgIconComponent;
  closeIcon?: SvgIconComponent;
  toggleState: boolean;
};

const Container: React.FCX<ContainerProps> = ({ toggleState, openIcon, closeIcon, ...rest }) => {
  const icon = (() => {
    if (toggleState) {
      return openIcon ? openIcon : ArrowDropDown;
    }

    return closeIcon ? closeIcon : ArrowRight;
  })();

  return <StyledUi {...rest} icon={icon} />;
};

/*---------------------------------------------- */
export default Container;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import Axios, { AxiosError, AxiosResponse } from 'axios';

export type Progress = 'idle' | 'loading' | 'success' | 'error';

export type Events<S, E> = {
  onStart?: () => void;
  onFinished?: () => void;
  onSuccess?: (response: S) => void;
  onError?: (error?: AxiosResponse<E>) => void;
};

type State<S, E> = {
  data: S;
  progress: Progress;
  error?: AxiosResponse<E>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApiRequest<S, E = any>(
  initialVal: S,
  requestCreator: () => (() => Promise<S>) | undefined,
  eventCreator: () => Events<S, E>
): void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApiRequest<S, E = any>(
  initialVal: S,
  requestCreator: () => (() => Promise<S>) | undefined
): State<S, E>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApiRequest<S, E = any>(
  initialVal: S,
  requestCreator: () => (() => Promise<S>) | undefined,
  eventCreator?: () => Events<S, E>
) {
  const isShutdown = useRef<boolean>(false);
  const [state, setState] = useState<State<S, E>>({
    data: initialVal,
    error: undefined,
    progress: 'idle',
  });

  useEffect(() => {
    isShutdown.current = false;

    const request = requestCreator();
    if (request != null) {
      (async () => {
        const { onStart, onFinished, onSuccess, onError } = eventCreator?.() || {};

        try {
          if (!onStart) {
            setState({ ...state, progress: 'loading' });
          } else {
            onStart();
          }

          const response = await request();

          if (!isShutdown.current) {
            if (onFinished) {
              onFinished();
            }

            if (onSuccess) {
              onSuccess(response);
            } else {
              setState({ data: response, error: undefined, progress: 'success' });
            }
          }
        } catch (e) {
          if (!isShutdown.current && Axios.isAxiosError(e)) {
            const error = (e as AxiosError).response;

            onFinished?.();
            onError?.(error);
            setState({ data: initialVal, error, progress: 'error' });
          }
        }
      })();
    }

    return () => {
      isShutdown.current = true;
    };
  }, [requestCreator, eventCreator]);

  return state;
}

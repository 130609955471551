import React, { ComponentProps } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import styled from 'styled-components';

/* -------------------- DOM -------------------- */
const Ui: React.FCX<SingleDatePickerShape> = ({ className, ...rest }) => (
  <div className={className}>
    {/* react-dates自体が少し大きいのでreact-datesにはsmallを固定で渡す*/}
    <SingleDatePicker {...rest} small />
  </div>
);

/* ------------------- Style ------------------- */
const StyledUi = styled(Ui)`
  .SingleDatePicker {
    > div {
      .SingleDatePickerInput__block {
        display: flex;
        min-height: ${(props) => (props.small ? 30 : 40)}px;
        align-items: center;
        border-radius: ${(props) => props.theme.shape.borderRadius}px;
        ${(props) =>
          props.focused
            ? `border: 1px solid ${props.theme.palette.primary.main};
             box-shadow 0 0 0 1px ${props.theme.palette.primary.main};`
            : 'border: 1px solid rgba(0, 0, 0, 0.23);'}

        .DateInput {
          input {
            font: inherit;
            border-bottom: none;
            color: ${(props) => props.theme.palette.text.primary};
          }

          ${(props) =>
            props.small &&
            `
            .DateInput_input__small {
              padding: 1px 7px 0px;
            }`}
        }
      }
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: ${(props) => props.theme.palette.primary.main};
      border-color: ${(props) => props.theme.palette.primary.dark};
    }
  }
`;

/* ----------------- Container ----------------- */
type SupportDefaultProps = 'id' | 'displayFormat' | 'onFocusChange' | 'isOutsideRange' | 'focused';
type RequiredProps = Omit<SingleDatePickerShape, SupportDefaultProps>;
type OptionalProps = Partial<Pick<SingleDatePickerShape, SupportDefaultProps>>;

type ContainerProps = RequiredProps & OptionalProps;

const Container: React.FCX<ContainerProps> = (props) => {
  const {
    id = 'ptolemy-single-date-picker',
    displayFormat = 'YYYY-MM-DD',
    focused: propsFocused = false,
    onFocusChange,
    isOutsideRange,
    ...rest
  } = props;

  const defaultIsOutsideRange = React.useCallback(() => false, []);

  const [focused, setDefaultFocusChange] = React.useState<boolean | null>(propsFocused);
  // propsでonFocusChangeを渡さなかった場合にこれらの変数を利用する
  const defaultOnFocusChanged = React.useCallback<SingleDatePickerShape['onFocusChange']>(
    ({ focused }) => setDefaultFocusChange(!!focused),
    []
  );

  const innerProps: ComponentProps<typeof StyledUi> = {
    ...rest,
    id,
    isOutsideRange: isOutsideRange !== undefined ? isOutsideRange : defaultIsOutsideRange,
    onFocusChange: onFocusChange ? onFocusChange : defaultOnFocusChanged,
    focused,
    displayFormat,
  };

  return <StyledUi {...innerProps} />;
};

/*---------------------------------------------- */
export default Container;

import React from 'react';
import { Grid, GridProps } from '@material-ui/core';
import styled from 'styled-components';

type Props = GridProps & {
  alignRightOnMobile?: boolean;
  denseItemsOnPC?: boolean;
};

const StyledUi = styled(({ alignRightOnMobile, denseItemsOnPC, ...rest }: Props) => (
  <Grid {...rest} />
))`
  ${(props) =>
    props.alignRightOnMobile &&
    `
    ${props.theme.breakpoints.down('xs')} {
      text-align: right;
    }
  `}

  ${(props) =>
    props.denseItemsOnPC &&
    props.spacing &&
    `
    ${props.theme.breakpoints.up('sm')} {
      .MuiGrid-item {
        padding: ${(props.theme.spacing(props.spacing) / 2) * 0.75}px;
      }

      .MuiGrid-spacing-xs-1 {
        width: calc(100% + ${props.theme.spacing(props.spacing) * 0.75}px);
        margin: -${(props.theme.spacing(props.spacing) / 2) * 0.75}px;
      }
    }
  `}
`;

export default StyledUi;
